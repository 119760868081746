import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_CHECKOUT_API_BASE_URL;

export type PackageToPromoCode = {
    id: number;
    active: number;
    package_id: number;
    package_name: string;
    promocode_id: number;
};
export type ValidatePromoCodeResponse = {
    data: {
        isValid: boolean;
        id: number;
        code: string;
        pricebook: unknown;
        type: number;
        value: number;
        product_to_promocode: unknown;
        package_to_promocode?: PackageToPromoCode[];
        message?: string;
    };
};

export const validatePromoCode = async (
    promoCode: string
): Promise<ValidatePromoCodeResponse['data']> => {
    const url = `${API_BASE_URL}/promocodes-public/promoCode/validate/${promoCode}`;

    const response = await axios.get<ValidatePromoCodeResponse>(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    });

    if (!response.data.data) {
        throw new Error('No data found in validate promocode response');
    }

    return response.data.data;
};
