import styled from 'styled-components';

import {
    AccountIcon,
    ContractIcon,
    ControllerIcon,
    LogoutIcon,
    NotesIcon,
    PackageIcon,
    PhoneIcon,
    PricebookIcon,
    PromocodeIcon,
    PropertyIcon,
    SearchIcon,
} from 'assets/pandoraIcons';
import Cookies from 'js-cookie';
import { ReactElement } from 'react';
import { GraphUpArrow } from 'react-bootstrap-icons';
import { getColourByStage } from 'styles/theme';
import { getCookieDomain } from 'utils/getCookieDomain';
import { getPandoraDomain } from 'utils/getPandoraDomain';
import { IconContainer, Item } from './Item';

const Container = styled.nav`
    display: flex;
    flex-direction: column;
    width: 17.5rem;
    min-height: 100vh;
    background-color: ${() => getColourByStage(process.env.REACT_APP_STAGE)};
`;
const Button = styled.button`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem;
    padding-top: 0.5rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.gray_000};
    background-color: inherit;
    border: none;
    font-size: inherit;

    :hover {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;

        color: ${(props) => props.theme.colors.yellow};
    }
`;
interface Link {
    text: string;
    link: string;
    icon: ReactElement;
    react?: boolean;
}

export const MenuBar = () => {
    const links: Link[] = [
        { text: 'Search', link: '/search', icon: <SearchIcon /> },
        { text: 'Accounts', link: '/accounts', icon: <AccountIcon /> },
        { text: 'Properties', link: '/properties', icon: <PropertyIcon /> },
        { text: 'Contracts', link: '/contracts', icon: <ContractIcon />, react: true },
        { text: 'Analytics', link: '/analytics', icon: <GraphUpArrow /> },
        { text: 'Notes', link: '/notes', icon: <NotesIcon /> },
        { text: 'Packages', link: '/packages', icon: <PackageIcon /> },
        { text: 'Pricebooks', link: '/pricebooks', icon: <PricebookIcon />, react: true },
        { text: 'Promo Codes', link: '/promo-codes', icon: <PromocodeIcon /> },
        { text: 'Sales Portal', link: '/sales-portal', icon: <PhoneIcon />, react: true },
        { text: 'Controller', link: '/controller', icon: <ControllerIcon /> },
    ];

    const renderLinks = () => {
        return links.map(({ text, link, icon, react }) => {
            const pandoraUrl = getPandoraDomain(process.env.REACT_APP_STAGE, react);
            return <Item key={link} text={text} link={`${pandoraUrl}${link}`} Icon={icon} />;
        });
    };

    const handleLogout = () => {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('isLoggingOut', 'true', { domain });

        window.location.assign(`${window.location.origin}/login`);
    };

    return (
        <Container>
            {renderLinks()}
            <Button type="button" onClick={handleLogout}>
                <IconContainer>
                    <LogoutIcon />
                </IconContainer>
                Log out
            </Button>
        </Container>
    );
};
