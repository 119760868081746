import { CatalogueResponse } from '../types';

export const getCatalogue = async (pricebookId: number) => {
    // TODO to use axios
    const getCatalogueResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/pricebooks/model/pricebooks/catalogue/v2/${pricebookId}`,
        {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
            },
            mode: 'cors',
        }
    );
    const response: CatalogueResponse = await getCatalogueResponse.json();

    if (response.error || typeof response.data === 'undefined') {
        // eslint-disable-next-line no-console
        console.error('Response error', response.error);
        throw new Error('There was an error fetching the catalogue');
    }

    return response.data;
};
