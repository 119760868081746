import { MenuBar } from 'components/menu-bar';
import { useAuthenticatedUser } from 'hooks/useAuthenticatedUser';
import Cookies from 'js-cookie';
import { Navigate, useSearchParams } from 'react-router-dom';
import { getCookieDomain } from 'utils/getCookieDomain';

import { Params } from 'api/contract';
import { useContractQuery } from 'api/contract/useContractQuery';
import { NewTabIcon } from 'assets/pandoraIcons';
import { Header } from 'components/NewHeader';
import { SalesPortalProvider } from 'features/salesPortal/contexts/SalesPortalContext';
import { BoilerInformationForm } from '../components/BoilerInformationForm';
import styles from './index.module.scss';

export const SalesPortalSuccess = () => {
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();
    const [searchParams] = useSearchParams();
    const contractId = Number(searchParams.get('contract-id'));
    const propertyId = searchParams.get('property-id');

    const contractParams: Params = {
        expand: 'property,account,package',
    };

    const { isLoading: isLoadingContract, data: contractData } = useContractQuery({
        contractId,
        params: contractParams,
    });

    if (isLoadingUser || isLoadingContract) {
        return <h5>Loading</h5>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <SalesPortalProvider>
                <MenuBar />
                <div className={styles.container}>
                    <Header text="Sales Portal" />
                    {contractId === null || propertyId === null ? (
                        <div className={styles.boilerForm}>
                            <p className={styles.errorMessage}>
                                Hmm, something went wrong displaying the boiler form. Please report
                                this to the assigned support ranger.
                            </p>
                        </div>
                    ) : (
                        <div className={styles.boilerForm}>
                            <h4>
                                Successfully created customer{' '}
                                <a
                                    href={`/contract/${searchParams.get('contract-id')}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    contract{' '}
                                    <span className={styles.contractLinkIcon}>
                                        <NewTabIcon />
                                    </span>
                                </a>
                            </h4>

                            {!contractData ? (
                                <p>
                                    An error ocurred loading the contract data, please contact
                                    support.
                                </p>
                            ) : (
                                <BoilerInformationForm contractData={contractData.data[0]} />
                            )}
                        </div>
                    )}
                </div>
            </SalesPortalProvider>
        </>
    );
};
