import { Button } from '@HometreeEngineering/component-library';
import { useSalesPortalContext } from 'features/salesPortal/contexts/SalesPortalContext';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from 'utils/formatPrice';
import { getCatalogue } from '../api/getCatalogue';
import { generateGCFlow } from '../api/goCardless';
import { Collapsible } from '../components/Collapsible';
import { ContactInformationForm } from '../components/ContactInformationForm';
import GoCardlessDropInComponent, { GCDropInRef } from '../components/GoCardlessDropIn';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { PaymentInformationForm } from '../components/PaymentInfoForm';
import { PlansTable } from '../components/PlansTable';
import { PropertyInformationForm } from '../components/PropertyInformationForm';
import { Package, SalesPortalState } from '../types';
import { handleGoCardlessSuccess } from '../utils/handleFormSubmission';
import styles from './salesPortalForm.module.scss';

export const SalesPortalForm = () => {
    const { state, dispatch } = useSalesPortalContext();
    const navigate = useNavigate();
    const [packagePrices, setPackagePrices] = useState<Package[]>([]);
    const [collapsedSections, setCollapsedSections] = useState<{
        contactForm: boolean;
        propertyForm: boolean;
        paymentForm: boolean;
    }>({
        contactForm: true,
        propertyForm: true,
        paymentForm: true,
    });
    const gcDropInRef = useRef<GCDropInRef>(null);
    const [gcDropInOptions, setGcDropInOptions] = useState<{
        flowID: string;
        environment: string;
    } | null>(null);

    const contactFormRef = useRef<HTMLDivElement>(null);
    const paymentFormRef = useRef<HTMLDivElement>(null);
    const propertyFormRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // TODO put this in its own component
        if (
            collapsedSections.paymentForm === false &&
            Object.values(state.formSectionValid).filter((b) => b === false).length === 0
        ) {
            generateGCFlow(state).then((response) => {
                if (response) {
                    setGcDropInOptions({
                        flowID: response.flowID,
                        environment: response.environment,
                    });
                }
            });
        }

        // TODO catch any errors, send to sentry and show feedback to user
    }, [state, state.property.postcode, collapsedSections.paymentForm]);

    useEffect(() => {
        setPackagePrices([]);
        // Load prices based on pricebook
        const a = async () => {
            const catalogue = await getCatalogue(state.plan.pricebook_id);

            setPackagePrices(catalogue.items[0].pricebook_entries.packages);
        };
        a();
    }, [state.plan.pricebook_id]);

    useEffect(() => {
        // Update selected plan wording and price
        const packageObj = packagePrices.find(
            (packagePrice) => packagePrice.package_id === state.plan.package_id
        );

        if (packageObj) {
            const priceObj = packageObj.prices.find(
                (price) => price.contribution === state.plan.contribution
            );

            if (!priceObj) {
                throw new Error(
                    `No price found for contribution ${state.plan.contribution} on package ${state.plan.package_id}`
                );
            }

            dispatch({
                type: 'setPlan',
                data: {
                    package_name: `${packageObj.name}`,
                    monthly_payment_amount: Number(priceObj.price),
                    products: packageObj.products,
                },
            });
        }
    }, [
        state.plan.package_id,
        state.plan.billing,
        state.plan.contribution,
        packagePrices,
        dispatch,
    ]);

    const onRegisterCustomerClick = () => {
        if (Object.values(state.formSectionValid).filter((b) => b === false).length === 0) {
            // Form is valid continue.
            if (gcDropInRef && gcDropInRef.current && state.payment.method === 'directDebit') {
                gcDropInRef.current.handleGCDropInOpen();
            }

            // TODO handle undefined states
        }
    };

    const onPackageSelect = () => {
        setCollapsedSections((prev) => ({
            ...prev,
            contactForm: false,
        }));

        setTimeout(() => {
            if (contactFormRef.current) {
                window.scrollTo({
                    top: contactFormRef.current.offsetTop - 120,
                    behavior: 'smooth',
                });
            }
        }, 50);
    };

    const onEachFormSubmit = (section: keyof SalesPortalState['formSectionValid']) => () => {
        if (section === 'contact') {
            setCollapsedSections((prev) => ({
                ...prev,
                propertyForm: false,
            }));

            setTimeout(() => {
                if (propertyFormRef.current) {
                    window.scrollTo({
                        top: propertyFormRef.current.offsetTop - 120,
                        behavior: 'smooth',
                    });
                }
            }, 50);
        }

        if (section === 'property') {
            setCollapsedSections((prev) => ({
                ...prev,
                paymentForm: false,
            }));

            setTimeout(() => {
                if (paymentFormRef.current) {
                    window.scrollTo({
                        top: paymentFormRef.current.offsetTop - 120,
                        behavior: 'smooth',
                    });
                }
            }, 50);
        }
    };

    return (
        <>
            <div className={styles.container}>
                <PlansTable onPackageSelect={onPackageSelect} packagePrices={packagePrices} />
            </div>
            <div className={styles.selectedPlan}>
                <div>
                    <p>
                        Selected plan: {state.plan.package_name}{' '}
                        <span>
                            (
                            {formatPrice(
                                state.plan.billing === 'annual'
                                    ? state.plan.monthly_payment_amount * 12
                                    : state.plan.monthly_payment_amount
                            )}
                            /{state.plan.billing === 'annual' ? 'year' : 'month'})
                        </span>
                    </p>
                </div>
            </div>
            <div className={`${styles.container} ${styles.formGroup}`}>
                <Collapsible
                    header={
                        <div className={styles.header} ref={contactFormRef}>
                            <h4>Contact information</h4>
                        </div>
                    }
                    collapsed={collapsedSections.contactForm}
                    onHeaderClick={() => {
                        setCollapsedSections((prev) => ({
                            ...prev,
                            contactForm: !prev.contactForm,
                        }));
                    }}
                >
                    <ContactInformationForm onSubmit={onEachFormSubmit('contact')} />
                </Collapsible>

                <Collapsible
                    header={
                        <div className={styles.header} ref={propertyFormRef}>
                            <h4>Property information</h4>
                        </div>
                    }
                    collapsed={collapsedSections.propertyForm}
                    onHeaderClick={() => {
                        setCollapsedSections((prev) => ({
                            ...prev,
                            propertyForm: !prev.propertyForm,
                        }));
                    }}
                >
                    <PropertyInformationForm onSubmit={onEachFormSubmit('property')} />
                </Collapsible>

                <Collapsible
                    header={
                        <div className={styles.header} ref={paymentFormRef}>
                            <h4>Payment information</h4>
                        </div>
                    }
                    collapsed={collapsedSections.paymentForm}
                    onHeaderClick={() => {
                        setCollapsedSections((prev) => ({
                            ...prev,
                            paymentForm: !prev.paymentForm,
                        }));
                    }}
                >
                    <>
                        <PaymentInformationForm />
                    </>
                </Collapsible>

                {gcDropInOptions && (
                    <GoCardlessDropInComponent
                        gcDropInOptions={{
                            billingRequestFlowID: gcDropInOptions.flowID,
                            environment: gcDropInOptions.environment.toLowerCase(),
                            onSuccess: handleGoCardlessSuccess(state, dispatch, navigate),
                            onExit: () => {},
                        }}
                        ref={gcDropInRef}
                    />
                )}

                <Button
                    onClick={onRegisterCustomerClick}
                    isDisabled={
                        Object.values(state.formSectionValid).filter((b) => b === false).length !==
                        0
                    }
                >
                    Register Customer
                </Button>
            </div>
            {state.request.isRequesting && <LoadingOverlay />}
        </>
    );
};
