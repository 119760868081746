import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_CHECKOUT_API_BASE_URL;

interface ValidatePropertyResponse {
    contractExist: boolean;
}

export const validatePropertyContract = async (postcode: string, street: string) => {
    const responseTest = await axios.get<ValidatePropertyResponse>(
        `${API_BASE_URL}/properties-public/pub/v1/properties/validate?postcode=${postcode}&street=${street}`
    );

    return responseTest.data.contractExist;
};
