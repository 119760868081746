import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';

export interface PromocodeType {
    id: number;
    date_created: string;
    date_modified: string;
    author: string;
    last_modified_by: string | null;
    active: number;
    name: string;
}

export interface PromocodeTypeResponse {
    data: PromocodeType[];
}

export const promocodeTypeSchema: JSONSchemaType<PromocodeTypeResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    date_created: { type: 'string' },
                    date_modified: { type: 'string' },
                    active: { type: 'number' },
                    author: { type: 'string' },
                    name: { type: 'string' },
                    last_modified_by: { type: 'string' },
                },
                required: [],
            },
        },
    },
    required: [],
};

export type GetPromocodeTypeResponse = PandoraErrorResponse | PromocodeTypeResponse;

export const getPromocodeTypeResponseSchema: JSONSchemaType<GetPromocodeTypeResponse> = {
    type: 'object',
    anyOf: [promocodeTypeSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};
