import { useState } from 'react';
import { ReactComponent as PlusIconThick } from '../../assets/plus-thick.svg';
import { ReactComponent as MinusIconThick } from '../../assets/minus-thick.svg';
import styles from './index.module.scss';

interface Props {
    header: JSX.Element | string;
    children: JSX.Element;
    id?: string;
    disabled?: boolean;
    variant?: 'directDebit' | 'faq' | 'inclusions';
    collapsed?: boolean;
    onHeaderClick?: () => void;
}

export const Collapsible = ({
    header,
    children,
    disabled,
    id,
    variant = 'directDebit',
    collapsed,
    onHeaderClick,
}: Props): JSX.Element => {
    const closedIcon = <PlusIconThick className={styles.plusIconThick} />;
    const openIcon = <MinusIconThick className={styles.plusIconThick} />;
    const [isCollapsedInternal, setIsCollapsedInternal] = useState(true);

    const onHeaderClickInternal = () => {
        if (typeof onHeaderClick !== 'undefined') {
            onHeaderClick();
        } else {
            setIsCollapsedInternal((prev) => !prev);
        }
    };

    const isCollapsed = typeof collapsed !== 'undefined' ? collapsed : isCollapsedInternal;

    return (
        <div
            className={`${styles.collapsibleContainer} ${isCollapsed ? styles.collapsed : ''} ${
                disabled ? styles.disabled : ''
            } ${styles[variant]}`}
        >
            <button
                className={styles.collapsibleHeader}
                type="button"
                tabIndex={0}
                onClick={onHeaderClickInternal}
                onKeyDown={onHeaderClickInternal}
                disabled={!!disabled}
                id={id || undefined}
            >
                {header}

                {isCollapsed ? closedIcon : openIcon}
            </button>
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default Collapsible;
