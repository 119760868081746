import format from 'date-fns/format';
import { SalesPortalState } from '../types';

interface RequestPayload {
    first_name: string;
    last_name: string;
    email: string;
    phone1_prefix: string;
    phone1: string;
    address_line_1: string;
    postcode: string;
    town: string;
    country: string;
    start_date: string;
    description: string;
    package_id: number | null;
    redirect: string;
}

export type GCDropinOptions = {
    flowID: string;
    environment: string;
} | null;

export const generateGCFlow = async (state: SalesPortalState) => {
    const input: RequestPayload = {
        first_name: state.contact.firstName || '',
        last_name: state.contact.lastName || '',
        email: state.contact.email || '',
        phone1_prefix: state.contact.phonePrefix || '',
        phone1: state.contact.phone || '',
        address_line_1: state.property.street || '',
        postcode: state.property.postcode || '',
        town: state.property.town || '',
        country: state.property.country || '',
        start_date: state.payment.startDate ? format(state.payment.startDate, 'yyyy-MM-dd') : '',
        description:
            'Just 30 seconds more….enter your details below and we’ll have you up and running in no time.', // TODO needs changing for agents
        package_id: state.plan.package_id || null,
        redirect: window.location.href,
    };

    const response = await fetch(
        `${process.env.REACT_APP_INTEGRATION_API_BASE_URL}/integration/gocardless/flow`,
        {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(input),
        }
    );

    const {
        data: { flowID, environment },
    } = (await response.json()) as { data: { flowID: string; environment: string } };

    return { flowID, environment };
};
