import { Button, InputButtonGroup } from '@HometreeEngineering/component-library';
import { validatePromoCode } from 'features/salesPortal/api/validatePromoCode';
import { validateReferralCode } from 'features/salesPortal/api/validateReferralCode';
import { useSalesPortalContext } from 'features/salesPortal/contexts/SalesPortalContext';
import { PromoCode, SalesPortalState } from 'features/salesPortal/types';
import { useState } from 'react';
import styles from './index.module.scss';

export const PromoReferralCode = () => {
    const {
        state: { plan: planState, contact: contactState },
        dispatch,
    } = useSalesPortalContext();

    const [isVerifying, setIsVerifying] = useState(false);
    const [promoReferralCode, setPromoReferralCode] = useState('');
    const [error, setError] = useState('');
    const [outputMessage, setOutputMessage] = useState('');

    const handleReferralCodeUpdate = async (referralCodeString: string) => {
        const referralCodeRes = await validateReferralCode(referralCodeString, contactState.email);

        if (referralCodeRes?.code === 'disallowedReferralCode') {
            setError('Customers cannot use their own referral code.');
            return;
        }

        if (!referralCodeRes?.first_name) {
            setError(
                'This is an invalid referral code, please check for any typos with the customer.'
            );
            return;
        }

        dispatch({
            type: 'setPlan',
            data: {
                promocode: undefined,
                referral_code: referralCodeString,
            },
        });
    };

    const getPromoCodeReportText = (
        monthlyTotalPrice: number,
        promoCodeData: SalesPortalState['plan']['promocode']
    ) => {
        if (!promoCodeData) {
            return '';
        }

        if (promoCodeData.type === PromoCode.PERCENTAGE) {
            const newMonthlyTotalPrice = monthlyTotalPrice * (promoCodeData.value! / 100);

            return `${promoCodeData.value}% discount applied. Saving you £${(
                Math.round(newMonthlyTotalPrice) / 100
            ).toFixed(2)}`;
        }

        if (promoCodeData.type === PromoCode.VALUE) {
            // Value discounts don't mention how much it takes off and applies it annual
            // may mislead monthly payment users
            return `£${promoCodeData.value} discount applied over total amount paid annually.`;
        }

        if (promoCodeData.type === PromoCode.VOUCHER) {
            return `You will receive a £${promoCodeData.value} voucher.`;
        }

        if (promoCodeData.type === PromoCode.TRACKING) {
            return `code added.`;
        }

        return `Unsupported promocode type ${promoCodeData.type}`;
    };

    const handlePromoCodeUpdate = async (promoCodeString: string) => {
        const promoCodeRes = await validatePromoCode(promoCodeString);

        if (promoCodeRes.isValid === false) {
            setError(`Failed to apply promocode because "${promoCodeRes.message}"`);
            return;
        }

        if (
            promoCodeRes.code === null ||
            promoCodeRes.type === null ||
            promoCodeRes.value === null ||
            promoCodeRes.value === undefined
        ) {
            setError(
                'The promocode selected is not setup correctly, please contact your support team.'
            );
            return;
        }

        const promocodeObj: SalesPortalState['plan']['promocode'] = {
            code: promoCodeRes.code,
            type: promoCodeRes.type,
            value: promoCodeRes.value,
        };

        dispatch({
            type: 'setPlan',
            data: {
                promocode: promocodeObj,
                referral_code: undefined,
            },
        });

        setOutputMessage(getPromoCodeReportText(planState.monthly_payment_amount, promocodeObj));
    };

    const onPromoReferralCodeSubmit = async () => {
        setIsVerifying(true);
        setError('');

        try {
            if (promoReferralCode.startsWith('REF')) {
                await handleReferralCodeUpdate(promoReferralCode);
                return;
            }

            await handlePromoCodeUpdate(promoReferralCode);
        } catch (_e) {
            // TODO for sentry when done
            setError(
                'Something went wrong when validating this, please try again or contact support.'
            );
            return;
        } finally {
            setIsVerifying(false);
        }
    };

    const handlePromoReferralCodeRemoval = () => {
        setPromoReferralCode('');
        setOutputMessage('');
        dispatch({
            type: 'setPlan',
            data: {
                promocode: undefined,
                referral_code: undefined,
            },
        });
    };

    return (
        <div className={styles.referralAndPromo}>
            <InputButtonGroup
                id="mockInputButtonGroup"
                label="Promo code/referral code"
                onChange={(e) => {
                    setError('');
                    setPromoReferralCode(e.target.value.toUpperCase());
                }}
                onClick={onPromoReferralCodeSubmit}
                buttonText="Apply"
                value={promoReferralCode}
                placeholder="Enter a valid promo or referral code"
                disabled={isVerifying}
                error={error}
            />

            {outputMessage !== '' && (
                <div className={styles.promoApplied}>
                    <p>{outputMessage}</p>
                    <Button onClick={handlePromoReferralCodeRemoval}>Remove</Button>
                </div>
            )}
        </div>
    );
};
