import { useGoCardlessDropin, GoCardlessDropinOptions } from '@gocardless/react-dropin';
import { forwardRef, useImperativeHandle } from 'react';

interface GCDropInProps {
    gcDropInOptions: GoCardlessDropinOptions;
}

export interface GCDropInRef {
    handleGCDropInOpen: () => void;
}

const GCDropIn = forwardRef<GCDropInRef, GCDropInProps>(({ gcDropInOptions }, ref) => {
    const { open } = useGoCardlessDropin({ ...gcDropInOptions });

    useImperativeHandle(ref, () => ({
        handleGCDropInOpen: open,
    }));

    return <></>;
});

export default GCDropIn;
