import { useQuery } from 'react-query';
import { GetPackageByQueryKey, getPackages, Params } from '.';

export const usePackagesQuery = ({
    packageId,
    params,
}: { packageId?: number; params?: Params } = {}) => {
    const query = ['packages'] as unknown as GetPackageByQueryKey;

    if (packageId) {
        query.push(packageId);
    }
    if (params) {
        query.push(params);
    }

    return useQuery(query, getPackages, {});
};
