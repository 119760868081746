import { Button, TextInput, Toggle, Checkbox } from '@HometreeEngineering/component-library';
import { Contract } from 'api/contract/types';
import { CheckIcon, EditIcon, HistoryIcon, NewTabIcon } from 'assets/pandoraIcons';
import Dropdown from 'components/Dropdown';
import { ContractBillingTypesResponse, ContractTypesResponse } from 'features/contract/api/types';
import { UpdateContractInput } from 'features/contract/api/updateContract';
import useAutoSizeTextArea from 'features/contract/hooks/useAutoSizeTextArea';
import { useUpdateContractMutation } from 'features/contract/hooks/useUpdateContractMutation';
import { ContractBillingType } from 'features/contract/types/ContractBillingType';
import { getPaymentAmountForBillingType } from 'features/contract/utils/getAmount';
import { getContractTypeName } from 'features/contract/utils/getContractTypeName';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { formatPrice } from 'utils/formatPrice';
import { isSmartCoverPackage } from 'utils/getPackage';
import { getPandoraDomain } from 'utils/getPandoraDomain';
import toastConfig from 'utils/toastConfig';
import ContractHistory from '../ContractHistory';
import WarningModal from '../ContractModals/WarningModal';
import Appliances from './Appliances';
import styles from './index.module.scss';

const PlanAndPricing = ({
    data,
    typesData,
    billingTypesData,
}: {
    data: Contract;
    typesData: ContractTypesResponse | undefined;
    billingTypesData: ContractBillingTypesResponse | undefined;
}) => {
    const [billingAmount, setBillingAmount] = useState<string | undefined>(undefined);
    const [isDropdownOpen, setIsDropDownOpen] = useState(false);
    const [amountError, setAmountError] = useState('');
    const updateContractMutation = useUpdateContractMutation();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isToggleOn, setIsToggleOn] = useState(!!data.automatically_renew);
    const options = ['0', '60', '95'];
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [canSubmit, setCanSubmit] = useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useAutoSizeTextArea('packageType', textAreaRef.current, data.package.name);

    const pandoraDomain = getPandoraDomain(process.env.REACT_APP_STAGE);

    useEffect(() => {
        if (data.contribution) setSelectedOption(`${data.contribution / 100}`);
    }, [data.contribution]);

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleChange = () => {
        setIsToggleOn(!isToggleOn);
        setCanSubmit(true);
    };

    const isOpenrent = data.is_read_only === 1;

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (isOpenrent) {
            toast.error('This contract is managed by Openrent, changes cannot be made.');
            return;
        }
        try {
            let dataToSubmit = { id: data.id } as UpdateContractInput;
            if (billingAmount) {
                const formatted = billingAmount.replaceAll('£', '');
                const amountToSubmit = parseFloat(formatted);
                const adjustedQuererlyAmount =
                    ContractBillingType.Quarterly === data.billing_type
                        ? (amountToSubmit * 100) / 3
                        : null;
                const adjustedYearlyAmount =
                    ContractBillingType.Yearly === data.billing_type
                        ? (amountToSubmit * 100) / 12
                        : null;
                const finalAmount =
                    adjustedQuererlyAmount ?? adjustedYearlyAmount ?? amountToSubmit * 100;
                dataToSubmit = { ...dataToSubmit, payment_amount: finalAmount };
            }
            if (
                selectedOption &&
                parseInt(selectedOption, 10) * 100 !== data.contribution &&
                !isSmartCoverPackage(data.package_id)
            ) {
                dataToSubmit = {
                    ...dataToSubmit,
                    contribution: parseInt(selectedOption, 10) * 100,
                };
            }

            if (!!data.automatically_renew !== isToggleOn) {
                dataToSubmit = {
                    ...dataToSubmit,
                    automatically_renew: isToggleOn,
                };
            }

            const res = await updateContractMutation.mutateAsync(dataToSubmit);
            setModalOpen(false);
            setIsDropDownOpen(false);
            setBillingAmount(undefined);
            toast.success(res.data?.message, toastConfig);
        } catch (error) {
            toast.error((error as Error)?.message || 'Error updating contract');
        }
    };

    const onEdit = (e: string) => {
        setIsDropDownOpen(true);
        const cleanValue = e.replaceAll('£', '');
        if (!Number(cleanValue)) {
            setAmountError('Only floating point number values are accepted');
            setBillingAmount(cleanValue);
        } else {
            setBillingAmount(cleanValue);
            setAmountError('');
        }
    };

    const onCancel = () => {
        setIsDropDownOpen(false);
        setBillingAmount(undefined);
        setSelectedOption(`${data.contribution / 100}`);
        setAmountError('');
        setCanSubmit(false);
        setIsToggleOn(!!data.automatically_renew);
    };
    const onSave = () => {
        if (!amountError && billingAmount) {
            const cleanValue = billingAmount.replaceAll('£', '');
            const formattedPrice = formatPrice(parseFloat(cleanValue) * 100).replace('£', '');
            setBillingAmount(formattedPrice);
            setIsDropDownOpen(false);
            setCanSubmit(true);
        }
    };

    const getChangeText = () => {
        let change = '';

        if (billingAmount) {
            change += ` This customer will have an amount of £${billingAmount}.`;
        }

        if (selectedOption && parseInt(selectedOption, 10) * 100 !== data.contribution) {
            change += ` This customer will have a call-out fee of £${selectedOption}.`;
        }

        if (!!data.automatically_renew !== isToggleOn) {
            change += ` Automatically renew will be turned ${isToggleOn ? 'on' : 'off'}.`;
        }

        return <strong>{change}</strong>;
    };

    const contractTypeForContract = getContractTypeName(typesData?.data, data);
    const billingType = billingTypesData?.data?.find((bt) => bt.id === data.billing_type)?.name;
    const gas = data.relations?.product_to_contract?.filter(
        (gasFire) => gasFire.product_name === 'Gas Fire' && gasFire.quantity
    );
    const totalQuantity = gas?.reduce((total, item) => {
        return total + Number(item.quantity);
    }, 0);

    const threeYearFixedPrice = data.relations?.product_to_contract?.find(
        (addon) => addon.product_name === '3 Year Fixed Price'
    );

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <div className={styles.contentContainer}>
                    <h5 className={!isOpenrent ? styles.contentValue : undefined}>
                        {data.relations?.appliances?.length > 1
                            ? 'Appliances & Pricing'
                            : 'Plan & Pricing'}
                    </h5>
                    {!isOpenrent ? <h6 className={styles.contentType}>Automatic Renewal</h6> : null}
                </div>
                {!isOpenrent ? <Toggle onChange={handleChange} checked={isToggleOn} /> : null}
            </div>
            <div className={styles.section}>
                <div
                    className={`${styles.contractElements} ${
                        !billingAmount ? styles.placeholderInput : ''
                    }`}
                >
                    <TextInput
                        id="billingAmount"
                        label="Billing amount"
                        placeholder={`${getPaymentAmountForBillingType(
                            data.payment_amount,
                            data.billing_type
                        )}`}
                        value={billingAmount ? `£${billingAmount}` : ''}
                        onChange={onEdit}
                        icon={
                            <>
                                <HistoryIcon
                                    className={`${styles.icon} ${isHistoryOpen ? styles.open : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsHistoryOpen(!isHistoryOpen);
                                    }}
                                />
                                <EditIcon className={styles.icon} />
                            </>
                        }
                        showDropdown={isDropdownOpen}
                        dropdownChildren={
                            <div className={styles.dropdownButtons}>
                                <Button onClick={onCancel} variant="outlined">
                                    Cancel
                                </Button>
                                <Button onClick={onSave}>Save</Button>
                            </div>
                        }
                        error={amountError}
                        disabled={isOpenrent}
                    />
                    <ContractHistory
                        data={data}
                        isOpen={isHistoryOpen}
                        historyKey="payment_amount"
                        alignment={styles.historyAlignment}
                    />
                    <TextInput
                        id="billingType"
                        label="Billing type"
                        placeholder={`${billingType}`}
                        value={`${billingType}`}
                        readOnly
                        disabled
                    />
                    {!isSmartCoverPackage(data.package_id) ? (
                        <div className={styles.dropdownContribution}>
                            <p className={styles.contributionTitle}>Call-out fee</p>
                            <Dropdown
                                options={options}
                                selectedOption={selectedOption}
                                setSelectedOption={(val: SetStateAction<string>) => {
                                    setSelectedOption(val);
                                    setCanSubmit(true);
                                }}
                                disabled={isOpenrent}
                            />
                        </div>
                    ) : (
                        <TextInput
                            id="contribution"
                            label="Call-out fee"
                            placeholder={`${data.contribution / 100}`}
                            value={`${data.contribution / 100}`}
                            disabled
                            readOnly
                        />
                    )}
                    <TextInput
                        id="customerType"
                        label="Customer type"
                        placeholder={contractTypeForContract || '-'}
                        value={`${contractTypeForContract || '-'}`}
                        readOnly
                        disabled
                    />

                    <label htmlFor="packageType" className={styles.packageTypeContainer}>
                        Package type
                        <textarea
                            className={styles.packageType}
                            id="packageType"
                            name="packageType"
                            placeholder={`${data.package?.name}`}
                            value={`${data.package?.name || '-'}`}
                            readOnly
                            disabled
                            rows={1}
                            ref={textAreaRef}
                        />
                    </label>
                    <TextInput
                        id="gasFire"
                        label="Gas Fire"
                        className={!totalQuantity ? styles.noBorder : ''}
                        placeholder="--"
                        value={`${totalQuantity || '--'}`}
                        readOnly
                        disabled
                        testId="gasFireInput"
                        icon={
                            gas?.find((g) => g.id)?.id ? (
                                <a
                                    aria-label="navigate to product-to-contract page"
                                    className={styles.linkIcon}
                                    href={`${pandoraDomain}/product-to-contract/${
                                        gas?.find((g) => g.id)?.id
                                    }`}
                                >
                                    <NewTabIcon className={styles.icon_link} />
                                </a>
                            ) : (
                                <></>
                            )
                        }
                    />
                    {data.alternative_accommodation ? (
                        <div className={styles.accommodationContainer}>
                            <CheckIcon />
                            <p>Alternative accommodation</p>
                        </div>
                    ) : null}
                    {data.relations?.appliances?.length ? (
                        <Appliances appliances={data.relations?.appliances} />
                    ) : null}
                </div>
                <div className={styles.fixedPriceAddon}>
                    <label htmlFor="3yfp">
                        <Checkbox
                            id="3yfp"
                            onChange={() => {}}
                            checked={!!threeYearFixedPrice}
                            ariaLabel="Price fixed for 3 years addon"
                        >
                            {' '}
                        </Checkbox>
                        Price fixed for 3 years
                    </label>
                </div>
                <div className={styles.submitButtons}>
                    <Button onClick={onCancel} variant="outlined" isDisabled={!canSubmit}>
                        Cancel
                    </Button>
                    <Button onClick={() => setModalOpen(true)} isDisabled={!canSubmit}>
                        Confirm
                    </Button>
                </div>
            </div>
            {isModalOpen ? (
                <WarningModal
                    isOpen={isModalOpen}
                    setIsOpen={setModalOpen}
                    warningMessage={
                        <span>
                            You are about to make changes to this customer&apos;s account.
                            {getChangeText()} Please cancel if this was made in error.
                        </span>
                    }
                    onCancel={handleCancel}
                    onSave={handleSubmit}
                />
            ) : null}
        </div>
    );
};

export default PlanAndPricing;
