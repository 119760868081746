import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import { isPandoraErrorResponse } from 'api/utils/isPandoraErrorResponse';
import { QueryFunction } from 'react-query';
import { PACKAGES_BASE_PATH } from 'utils/constants';
import { getMandatoryEnvVar } from 'utils/env';
import { PackagesWithRelations, getPackageResponseSchema } from './types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export interface Params {
    log?: boolean;
    paymentStatus?: boolean;
    expand?: string;
}

export type GetPackageByQueryKey = [string, number, Params];

export const getPackages: QueryFunction<PackagesWithRelations, GetPackageByQueryKey> = async ({
    queryKey,
}) => {
    const [, packageId, params] = queryKey;

    let paramsString = '';

    if (params) {
        const { log, paymentStatus, expand } = params;
        const searchParams = new URLSearchParams('');
        searchParams.append('expand', `${expand}`);
        searchParams.append('log', `${log}`);
        searchParams.append('payment_status', `${paymentStatus}`);

        // Replace the encoded commas with commas
        paramsString += `?${searchParams.toString().replace(/%2C/g, ',')}`;
    }

    const token = await getAmplifyIdTokenFromCookie();
    const response = await fetch(
        `${API_BASE_URL}/${PACKAGES_BASE_PATH}/packages/${packageId || ''}${paramsString}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        }
    );

    if (response.status === 401) {
        window.location.replace(`${window.location.origin}/login`);
    }

    const data = await response.json();

    const ajv = new Ajv({ coerceTypes: true, strict: false });
    addFormats(ajv); // add formats for date-time format
    const validate = ajv.compile(getPackageResponseSchema);
    const valid = validate(data);

    if (!valid) {
        console.error(
            `Failed to validate getPackages response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    }

    if (isPandoraErrorResponse(data)) {
        throw new Error(`Error message from api: ${data.message}`);
    }

    return data;
};
