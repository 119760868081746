import { format } from 'date-fns';
import { SalesPortalState } from '../types';
import { calculatelDiscountAmount } from './calculatelDiscountAmount';

export const mapRegisterCustomerPayload = (state: SalesPortalState) => ({
    author: 'via sales portal in Pandora', // TODO amend to logged in user
    first_name: state.contact.firstName,
    last_name: state.contact.lastName,
    email: state.contact.email?.replace(/\s/g, ''),
    phone1_prefix: state.contact.phonePrefix,
    phone1: state.contact.phone,
    marketing_email: state.contact.marketing_email,
    marketing_phone: state.contact.marketing_phone,
    marketing_sms: state.contact.marketing_sms,
    referral_programme: false, // state.referralProgramme, // TODO marketing
    referral_code: state.plan.referral_code ? state.plan.referral_code.substring(3) : undefined,
    address_line_1: state.property.street,
    postcode: state.property.postcode,
    town: state.property.town,
    country: state.property.country,
    start_date: state.payment.startDate ? format(state.payment.startDate, 'yyyy-MM-dd') : undefined,
    package_id: state.plan.package_id,
    package_type: state.plan.customer_type,
    pricebook_id: state.plan.pricebook_id,
    payment_amount: Math.round(state.plan.monthly_payment_amount - calculatelDiscountAmount(state)),
    contribution: state.plan.contribution * 100, // TODO standardise money amounts across the app to be pence
    billing_type: state.plan.billing,
    gc_success_redirect: '', // Legacy needs removing in backend
    correspondence: {
        address_line_1: state.property.street,
        town: state.property.town,
        country: state.property.country,
        postcode: state.property.postcode,
    },
    ga_cid: undefined,
    http_referer: undefined,
    landingpage: undefined,
    querystring:
        state.plan.source && state.plan.source !== ''
            ? `?utm_medium=Partnerships&utm_source=${state.plan.source}`
            : undefined, // If we need to send more use URLSearcParams class
    partner_quote_id: undefined,
    transaction_id: undefined,
    ...(typeof state.payment.goCardless !== 'undefined' && {
        go_cardless: {
            billing_request_id: state.payment.goCardless?.billing_request_id,
            billing_request_flow_id: state.payment.goCardless?.billing_request_flow_id,
            creditor_id: state.payment.goCardless?.creditor_id,
            customer_id: state.payment.goCardless?.customer_id,
            customer_bank_account_id: state.payment.goCardless?.customer_bank_account_id,
            customer_billing_detail_id: state.payment.goCardless?.customer_billing_detail_id,
            mandate_request_id: state.payment.goCardless?.mandate_request_id,
            mandate_request_mandate_id: state.payment.goCardless?.mandate_request_mandate_id,
        },
    }),
    ...(state.plan.promocode && {
        promocode: state.plan.promocode.code,
    }),
    ...(state.plan.referral_code && {
        // Referral codes start with REF but we don't send that to the backend
        // Use referral code from URL params if customer has not inputted it
        referral_code: state.plan.referral_code.substring(3),
    }),
    // TODO chargebee payment intent send to register customer
    // ...(typeof state.chargebee !== 'undefined' && {
    //     chargebee: {
    //         payment_intent_id: state.chargebee?.payment_intent_id,
    //     },
    // }),
    version: '1.1.0',
});
