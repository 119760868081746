import styles from './index.module.scss';

interface Props {
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    name?: string;
    disabled?: boolean;
    children: JSX.Element | string;
}

function Radio({ id, onChange, checked, name, disabled, children }: Props) {
    return (
        <div className={`${styles.radioContainer} ${disabled && styles.disabled}`}>
            <label htmlFor={id}>
                <div>
                    <input
                        type="radio"
                        name={name || ''}
                        onChange={onChange}
                        id={id}
                        checked={checked}
                        disabled={disabled}
                    />
                    <span />
                </div>

                {children}
            </label>
        </div>
    );
}

export default Radio;
