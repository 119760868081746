import '@Hometree-styles/base.scss';
import LoginPrompt from 'features/LoginPrompt';
import { Claim } from 'features/claim/components';
import { ContractDetails } from 'features/contract/components/ContractDetails';
import Contracts from 'features/contract/components/Contracts';
import {
    EditPricebookEntry,
    NewPricebookEntry,
    Pricebook,
    PricebookEntry,
    Pricebooks,
} from 'features/pricebook/components';
import { SalesPortal } from 'features/salesPortal/SalesPortal';
import { SalesPortalSuccess } from 'features/salesPortal/SalesPortalSuccess';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'styles/theme';
import { getMandatoryEnvVar } from 'utils/env';

// TODO Add sentry initialisation
const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const contractDetailsEnabled = getMandatoryEnvVar('REACT_APP_FEATURE_TOGGLE_CONTRACT_DETAILS');

export const App = () => {
    const queryClient = new QueryClient();

    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={defaultTheme}>
                    <Toaster position="top-right" reverseOrder={false} />
                    <Container>
                        <Routes>
                            <Route path="/login" element={<LoginPrompt />} />
                            <Route path="/:claimId" element={<Claim />} />
                            <Route path="/pricebooks" element={<Pricebooks />} />
                            <Route path="/pricebooks/:pricebookId" element={<Pricebook />} />
                            <Route
                                path="/pricebooks/:pricebookId/new"
                                element={<NewPricebookEntry />}
                            />
                            <Route
                                path="/pricebooks/:pricebookId/:packageId"
                                element={<PricebookEntry />}
                            />
                            <Route
                                path="/pricebooks/:pricebookId/:packageId/edit"
                                element={<EditPricebookEntry />}
                            />
                            <Route path="/sales-portal" element={<SalesPortal />} />
                            <Route path="/sales-portal/success" element={<SalesPortalSuccess />} />
                            {contractDetailsEnabled === 'true' ? (
                                <Route path="/contract/:contractId" element={<ContractDetails />} />
                            ) : null}
                            {contractDetailsEnabled === 'true' ? (
                                <Route path="/contracts" element={<Contracts />} />
                            ) : null}
                        </Routes>
                    </Container>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </BrowserRouter>
    );
};
