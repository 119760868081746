import { Alert, AlertTitle } from '@mui/material';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { Activity as ActivityType } from 'features/claim/api/claimActivity/type';
import { useGetClaimActivityNotes } from 'features/claim/hooks/useGetClaimActivityNotes';
import { useToggle } from 'hooks/useToggle';
import { CreateActivityModal } from '../CreateActivityModal';
import { CreateActivityButton } from './CreateActivityButton';
import { NoteBlock } from './NoteBlock';
import style from './index.module.css';

export const Activity = () => {
    const [isActivityModalOpen, toggleCreateActivityModalOpen] = useToggle(false);
    const { activityNotes: activities, isLoading, isError } = useGetClaimActivityNotes();

    if (isLoading) {
        return (
            <section className={style.container}>
                <header className={style.headerContainer}>
                    <h5>Activity</h5>
                </header>
                <SkeletonLoader repeat={3} />
            </section>
        );
    }

    if (!activities || isError) {
        return (
            <section className={style.container}>
                <header className={style.headerContainer}>
                    <h5>Activity</h5>
                </header>
                <Alert severity="error">
                    <AlertTitle>Account Details Error</AlertTitle>
                    Unable to load account information for id
                </Alert>
            </section>
        );
    }

    const renderNoteBlocks = () => {
        if (!activities) {
            return null;
        }

        return activities
            .filter((activity) => activity.notes.length > 0)
            .map((activity: ActivityType) => {
                return (
                    <NoteBlock
                        key={activity.id}
                        note={activity.notes[0]}
                        createdAt={new Date(activity.dateCreated)}
                    />
                );
            });
    };

    return (
        <>
            <CreateActivityModal
                open={isActivityModalOpen}
                closeModal={() => {
                    toggleCreateActivityModalOpen();
                }}
            />
            <section className={style.container}>
                <header className={style.headerContainer}>
                    <h5>Activity</h5>
                    <CreateActivityButton toggleModal={toggleCreateActivityModalOpen} />
                </header>
                {renderNoteBlocks()}
            </section>
        </>
    );
};
