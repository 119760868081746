import { MenuBar } from 'components/menu-bar';
import { useAuthenticatedUser } from 'hooks/useAuthenticatedUser';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { getCookieDomain } from 'utils/getCookieDomain';

import { Header } from 'components/NewHeader';
import { SalesPortalProvider } from 'features/salesPortal/contexts/SalesPortalContext';
import styles from './index.module.scss';
import { SalesPortalForm } from './salesPortalForm';

export const SalesPortal = () => {
    const { isLoading: isLoadingUser, data: userData } = useAuthenticatedUser();

    if (isLoadingUser) {
        return <h5>Loading</h5>;
    }

    if (userData && !userData.isAuthenticated) {
        const domain = getCookieDomain(process.env.REACT_APP_STAGE);
        Cookies.set('last-visited', window.location.href, { domain });
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <SalesPortalProvider>
                <MenuBar />
                <div className={styles.container}>
                    <Header text="Sales Portal" />
                    <SalesPortalForm />
                </div>
            </SalesPortalProvider>
        </>
    );
};
