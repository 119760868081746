import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_INTEGRATION_API_BASE_URL;

export type ValidateReferralCodeResponse = {
    data: {
        first_name?: string;
        referralCode?: string;
        accountId?: string;
        message?: string;
        errorCode?: string;
        code?: string;
    };
};

export const validateReferralCode = async (
    referralCode: string,
    email: string
): Promise<ValidateReferralCodeResponse['data']> => {
    const url = `${API_BASE_URL}/integration/customer/verifyReferral/${referralCode.substring(3)}${
        email ? `?referrerAccountEmail=${encodeURIComponent(email)}` : ''
    }`;

    const response = await axios.get<ValidateReferralCodeResponse>(url, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        },
    });

    if (!response.data.data) {
        throw new Error('No data found in validate referral response');
    }

    return response.data.data;
};
