import { Button } from '@HometreeEngineering/component-library';
import {
    Account,
    Claim,
    ClaimdbClaim,
    Contract,
    GoCardlessSubscription,
    Note,
    Promocode,
    Property,
    Renewal,
} from 'api/contract/types';
import { Package, Packages } from 'api/packages/types';
import { PromocodeTypeResponse } from 'api/promocodes/types';
import { SearchResponseItem } from 'api/search/types';
import { CheckIcon, XCircle } from 'assets/pandoraIcons';
import { format } from 'date-fns';
import { formatPrice } from 'utils/formatPrice';
import { getPandoraDomain } from 'utils/getPandoraDomain';
import { mapContractStatus } from 'utils/mapContractStatus';
import { CancellationOptionsResponse } from '../api/cancellation.types';
import { ContractBillingTypesResponse, ContractTypes } from '../api/types';
import { RenewalStatus } from '../types/ContractBillingType';
import { formatLondonTime } from './formatLondonTime';
import { getPaymentAmountForBillingType } from './getAmount';
import { getContractTypeName } from './getContractTypeName';
import styles from './index.module.scss';

const pandoraDomain = getPandoraDomain(process.env.REACT_APP_STAGE);

export const formatValue = (value: any) => {
    return value || '-';
};

export const buildContractTableData = (
    data: Contract,
    billingtypesData: ContractBillingTypesResponse | undefined
) => {
    const renewalForContract = data?.relations?.renewals?.find(
        (renewal) => renewal.contract_id === data.id
    );
    const newTableData: Record<string, any> = {
        package: data?.package?.name,
        startDate: format(new Date(data.start_date), 'dd/MM/yyyy'),
        monthlyPrice: formatPrice(data.payment_amount),
        billingAmount: getPaymentAmountForBillingType(data.payment_amount, data.billing_type),
        claims: data.relations?.claimdb_claims?.length,
        renewalPrice: renewalForContract && formatPrice(renewalForContract.last_quoted_price),
        billingType: billingtypesData?.data?.find((bt) => bt.id === data.billing_type)?.name,
        callOutFee: formatPrice(data.contribution),
        id: data.id,
    };

    const formattedData = Object.entries(newTableData).reduce<Record<string, any>>(
        (acc, [key, value]) => {
            acc[key] = formatValue(value);
            return acc;
        },
        {}
    );

    return [formattedData];
};

export const buildContractsTableData = (
    data: Contract[] | undefined,
    billingtypesData: ContractBillingTypesResponse | undefined,
    typesData: ContractTypes[] | undefined,
    packagesData: Package[] | undefined
) => {
    const newTableData = data?.map((contract) => {
        const contractTypeForContract = getContractTypeName(typesData, contract);
        const currentPackage = packagesData?.find((pack) => pack.id === contract.package_id);
        const mappedContact: Record<string, any> = {
            active: contract?.active ? <CheckIcon /> : <XCircle className={styles.inactive} />,
            type: contractTypeForContract,
            status: mapContractStatus(contract.status),
            contract_number: <a href={`/contract/${contract.id}`}>{contract.customer_facing_id}</a>,
            linked_account: (
                <a href={`${pandoraDomain}/account/${contract.account_id}`}>
                    {contract.account_id}
                </a>
            ),
            linked_property: (
                <a href={`${pandoraDomain}/property/${contract.property_id}`}>
                    {contract.property_id}
                </a>
            ),
            author: contract.author,
            package: (
                <a href={`${pandoraDomain}/package/${currentPackage?.id}`}>
                    {currentPackage?.name}
                </a>
            ),
            start_date: format(new Date(contract.start_date), 'dd/MM/yyyy'),
            end_date: format(new Date(contract.end_date), 'dd/MM/yyyy'),
            modified_date: format(new Date(contract.date_modified), 'dd/MM/yyyy'),
            billing_amount: getPaymentAmountForBillingType(
                contract.payment_amount,
                contract.billing_type
            ),
            billing_type: billingtypesData?.data?.find((bt) => bt.id === contract.billing_type)
                ?.name,
            contribution: formatPrice(contract.contribution),
            id: contract.id,
        };

        const formattedData = Object.entries(mappedContact).reduce<Record<string, any>>(
            (acc, [key, value]) => {
                acc[key] = formatValue(value);
                return acc;
            },
            {}
        );
        return formattedData;
    });

    return newTableData;
};

export const buildAccountTableData = (data: Account) => {
    const newTableData: Record<string, any> = {
        active: data?.active ? <CheckIcon /> : <XCircle className={styles.inactive} />,
        account_number: (
            <a href={`${pandoraDomain}/account/${data.id}`}>{data.customer_facing_id}</a>
        ),
        first_name: data.first_name,
        last_name: data.last_name,
        referral_code: data.referral_code,
        email: data.email,
        phone: data.phone1,
        correspondance_property: (
            <a href={`${pandoraDomain}/property/${data.correspondence_property_id}`}>
                {data.correspondence_property_id}
            </a>
        ),
        id: data.id,
    };

    const formattedData = Object.entries(newTableData).reduce<Record<string, any>>(
        (acc, [key, value]) => {
            acc[key] = formatValue(value);
            return acc;
        },
        {}
    );

    return [formattedData];
};

export const buildPropertyTableData = (data: Property) => {
    const newTableData: Record<string, any> = {
        active: data?.active ? <CheckIcon /> : <XCircle className={styles.inactive} />,
        address: <a href={`${pandoraDomain}/property/${data.id}`}>{data.address_line_1} </a>,
        town: data.town,
        postcode: data.postcode,
        last_service:
            data.last_service_date && formatLondonTime(new Date(`${data.last_service_date}Z`)),
        next_service: data.next_service && formatLondonTime(new Date(`${data.next_service}Z`)),
        boiler_age: data.boiler_age,
        id: data.id,
    };

    const formattedData = Object.entries(newTableData).reduce<Record<string, any>>(
        (acc, [key, value]) => {
            acc[key] = formatValue(value);
            return acc;
        },
        {}
    );

    return [formattedData];
};

export const buildClaimsTableData = (data: ClaimdbClaim[]) => {
    const newTableData = data.map((claim) => {
        const mappedClaim = {
            claim_id: <a href={`/${claim.id}`}>{claim.id}</a>,
            type: claim.type,
            status: claim.status,
            urgency: claim.sk_urgency,
            start_date: claim.date_created
                ? formatLondonTime(new Date(`${claim.date_created}Z`))
                : '-',
            vulnerable_customer:
                claim.sk_vulnerable_customer === 'Yes' ? (
                    <CheckIcon className={styles.icon} />
                ) : (
                    <XCircle className={`${styles.inactive} ${styles.icon}`} />
                ),
            cost: formatPrice(claim.charge_total_cost),
            id: claim.id,
        };
        const formattedData = Object.entries(mappedClaim).reduce<Record<string, any>>(
            (acc, [key, value]) => {
                acc[key] = formatValue(value);
                return acc;
            },
            {}
        );

        return formattedData;
    });

    return newTableData;
};

export const buildPMSClaimsTableData = (data: Claim[]) => {
    const newTableData = data.map((claim) => {
        const mappedClaim = {
            pms_id: <a href={`/${claim.id}`}>{claim.id}</a>,
            trade: claim.pms_trade,
            job_type: claim.job_type,
            claim_start_date: claim.claim_start_date
                ? formatLondonTime(new Date(`${claim.claim_start_date}Z`))
                : '-',
            created_date: claim.date_created
                ? formatLondonTime(new Date(`${claim.date_created}Z`))
                : '-',
            charge_final: formatPrice(claim.charge_final),
            summary: claim.summary,
            id: claim.id,
        };
        const formattedData = Object.entries(mappedClaim).reduce<Record<string, any>>(
            (acc, [key, value]) => {
                acc[key] = formatValue(value);
                return acc;
            },
            {}
        );

        return formattedData;
    });
    return newTableData;
};

export const buildGoCardlessTableData = (data: GoCardlessSubscription) => {
    const newTableData: Record<string, any> = {
        status: data?.status === 'active' ? <CheckIcon /> : <XCircle className={styles.inactive} />,
        amount: formatPrice(data.amount),
        billing_type: data.billing_type,
        start_date: format(new Date(data.start_date), 'dd/MM/yyyy'),
        id: data.id,
    };

    const formattedData = Object.entries(newTableData).reduce<Record<string, any>>(
        (acc, [key, value]) => {
            acc[key] = formatValue(value);
            return acc;
        },
        {}
    );

    return [formattedData];
};

export const buildRenewalsTableData = (
    data: Renewal[],
    packages: Packages | undefined,
    cancellationTypesData: CancellationOptionsResponse | undefined
) => {
    const newTableData = data.map((renewal) => {
        const statusId = renewal.status;
        const mappedRenewal = {
            id: <a href={`${pandoraDomain}/renewal/${renewal.id}`}>{renewal.id}</a>,
            active: renewal.active ? <CheckIcon /> : <XCircle className={styles.inactive} />,
            package: packages?.data?.find((pcg) => pcg.id === renewal.package_id)?.name ?? '-',
            status: statusId && RenewalStatus[statusId],
            cancellationType: renewal.cancellation_type
                ? cancellationTypesData?.data?.find((type) => type.id === renewal.cancellation_type)
                      ?.name
                : '-',
            renewal_start_date: renewal.start_date
                ? formatLondonTime(new Date(`${renewal.start_date}Z`))
                : '-',
            proposed_claim_count: renewal.claims_accounted_for,
            renewal_price: formatPrice(renewal.last_quoted_price),
            discount_applied: formatPrice(renewal.custom_renewal_discount),
            saving: formatPrice(renewal.saving),
        };
        const formattedData = Object.entries(mappedRenewal).reduce<Record<string, any>>(
            (acc, [key, value]) => {
                acc[key] = formatValue(value);
                return acc;
            },
            {}
        );

        return formattedData;
    });

    return newTableData;
};

export const buildPromocodeData = (
    data: Promocode | null,
    promocodeTypesData: PromocodeTypeResponse | undefined
) => {
    if (!data?.id) {
        return null;
    }
    const promocodeTypeName = promocodeTypesData?.data.filter((type) => type.id === data.type);

    const newTableData: Record<string, any> = {
        id: data.id,
        code: <a href={`${pandoraDomain}/promo-code/${data.id}`}>{data.code}</a>,
        type: promocodeTypeName && promocodeTypeName.length > 0 ? promocodeTypeName[0].name : null,
        description: data.description,
        expiry_date: data.expiry_date ? format(new Date(data.expiry_date), 'dd/MM/yyyy') : '-',
        active: data.active ? <CheckIcon /> : <XCircle className={styles.inactive} />,
        value: data.value,
    };

    const formattedData = Object.entries(newTableData).reduce<Record<string, any>>(
        (acc, [key, value]) => {
            acc[key] = formatValue(value);
            return acc;
        },
        {}
    );

    return [formattedData];
};

export const buildSearchPromocodeData = (
    data: SearchResponseItem[] | undefined,
    promocodeTypesData: PromocodeTypeResponse | undefined,
    handleSelect: {
        (id: number, code: string): void;
        (arg0: number, arg1: string): void;
    }
) => {
    if (!data) {
        return [];
    }

    const tableData = data.map((promo) => {
        const { entry } = promo;
        const promocodeTypeName = promocodeTypesData?.data.filter((type) => type.id === entry.type);
        if (entry?.active) {
            const newTableData: Record<string, any> = {
                id: entry.id,
                code: (
                    <Button onClick={() => handleSelect(entry.id as number, entry.code as string)}>
                        {entry?.code as string}
                    </Button>
                ),
                type:
                    promocodeTypeName && promocodeTypeName.length > 0
                        ? promocodeTypeName[0].name
                        : null,
                description: entry.description,
                family: entry.family,
                expiry_date: entry.expiry_date
                    ? format(new Date(entry.expiry_date), 'dd/MM/yyyy')
                    : '-',
                active: entry.active ? <CheckIcon /> : <XCircle className={styles.inactive} />,
                value: entry.value,
            };

            const formattedData = Object.entries(newTableData).reduce<Record<string, any>>(
                (acc, [key, value]) => {
                    acc[key] = formatValue(value);
                    return acc;
                },
                {}
            );

            return formattedData;
        }
        return null;
    });

    return tableData.filter((td) => td) as Record<string, any>[];
};

export const buildNotesTableData = (data: Note[]) => {
    if (!data.length) {
        return null;
    }
    const orderedData = [...data].reverse();
    const newTableData = orderedData.map((note) => {
        const mappedNotes = {
            id: note.id,
            note_id: <a href={`${pandoraDomain}/note/${note.id}`}>{note.id}</a>,
            active: note.active ? <CheckIcon /> : <XCircle className={styles.inactive} />,
            author: note.author,
            note: note.note,
            created_date: note.date_created
                ? formatLondonTime(new Date(`${note.date_created}Z`))
                : '-',
            modified_date: note.date_modified
                ? formatLondonTime(new Date(`${note.date_modified}Z`))
                : '-',
        };
        const formattedData = Object.entries(mappedNotes).reduce<Record<string, any>>(
            (acc, [key, value]) => {
                acc[key] = formatValue(value);
                return acc;
            },
            {}
        );

        return formattedData;
    });

    return newTableData;
};
