import { Button, Spinner, Table, TextInput } from '@HometreeEngineering/component-library';
import { Promocode } from 'api/contract/types';
import { usePromocodeTypesQuery } from 'api/promocodes/usePromocodeTypesQuery';
import { useSearchItemsQuery } from 'api/search/useSearchQuery';
import { ViewIcon } from 'assets/pandoraIcons';
import { useUpdateContractMutation } from 'features/contract/hooks/useUpdateContractMutation';
import {
    buildPromocodeData,
    buildSearchPromocodeData,
} from 'features/contract/utils/tableDataBuilder';
import { useDebounceValue } from 'hooks/useDebounceValue';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import toastConfig from 'utils/toastConfig';
import WarningModal from '../ContractModals/WarningModal';
import styles from '../index.module.scss';
import promocodeStyles from './index.module.scss';

const PromocodeTable = ({
    promocodeData,
    contractId,
}: {
    promocodeData: Promocode | null;
    contractId: number;
}) => {
    const [search, setSearch] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data: promocodeTypesData, isLoading } = usePromocodeTypesQuery();
    const debouncedSearchTerm = useDebounceValue(search, 500);
    const [selectedPromocode, setSelectedPromocode] = useState<{
        id: number;
        code: string;
    }>({ id: 0, code: '' });
    const { data: searchData, isLoading: isLoadingSearch } = useSearchItemsQuery(
        'promocode',
        debouncedSearchTerm
    );
    const updateContractMutation = useUpdateContractMutation();

    const headers = useMemo(
        () => [
            { key: 'active', value: 'Active' },
            { key: 'code', value: 'Code' },
            { key: 'type', value: 'Type' },
            { key: 'value', value: 'Value' },
            { key: 'family', value: 'Family' },
            { key: 'description', value: 'Description' },
            { key: 'expiry_date', value: 'Expiry Date' },
        ],
        []
    );

    const handleSelect = (id: number, code: string) => {
        setSelectedPromocode({ id, code });
        setIsModalOpen(true);
    };

    const data = useMemo(
        () => buildPromocodeData(promocodeData, promocodeTypesData),
        [promocodeData, promocodeTypesData]
    );

    const searchedData = useMemo(
        () => buildSearchPromocodeData(searchData?.data, promocodeTypesData, handleSelect),
        [searchData, promocodeTypesData]
    );

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const newPromocode = {
                id: contractId,
                promo_code_id: selectedPromocode?.id,
            };

            const res = await updateContractMutation.mutateAsync(newPromocode);
            setSearch('');
            setIsModalOpen(false);
            setShowSearch(false);
            toast.success(res.data?.message, toastConfig);
        } catch (error) {
            toast.error((error as Error)?.message || 'Error updating contract');
        }
    };

    return (
        <div className={`${styles.container}`}>
            <div className={styles.sectionTitleContainer}>
                <h6 className={promocodeStyles.promoTitle}>PROMO CODE</h6>
                <ViewIcon
                    onClick={() => setShowSearch(true)}
                    aria-label="enable search"
                    className={promocodeStyles.icon}
                />
            </div>
            <div className={styles.tableContainer}>
                {data && (
                    <Table headers={headers} data={data} customStyle={styles.differentHeader} />
                )}

                {showSearch && (
                    <div
                        className={`${promocodeStyles.promocodeContainer}  ${
                            promocodeStyles.section
                        } ${showSearch ? promocodeStyles.open : ''}`}
                    >
                        <TextInput
                            id="promocode"
                            testId="promocode"
                            placeholder="--"
                            value={search || ''}
                            onChange={(e) => {
                                setShowSearch(true);
                                setSearch(e);
                            }}
                        />
                        <Button
                            onClick={() => {
                                setSearch('');
                                setShowSearch(false);
                            }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                    </div>
                )}
            </div>
            {isLoadingSearch && <Spinner />}
            {showSearch && searchedData?.length && !!debouncedSearchTerm && !isLoadingSearch ? (
                <Table headers={headers} data={searchedData} customStyle={styles.differentHeader} />
            ) : (
                <>
                    {showSearch && !isLoading && !!debouncedSearchTerm && !isLoadingSearch && (
                        <p>
                            No Promo codes found with code <strong>{debouncedSearchTerm}</strong>.
                        </p>
                    )}
                </>
            )}
            {isModalOpen ? (
                <WarningModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    warningMessage={
                        <span>
                            You are about to make changes to this customer&apos;s account. This
                            customer will have the following promo code applied:{' '}
                            <strong>{selectedPromocode.code}</strong>. Please cancel if this was
                            made in error.
                        </span>
                    }
                    onCancel={handleCancel}
                    onSave={handleSubmit}
                />
            ) : null}
        </div>
    );
};

export default PromocodeTable;
