import { ToastOptions } from 'react-hot-toast';

export const errorToastOptions: ToastOptions = {
    duration: Infinity,
    style: {
        border: '1px solid #870e0e',
        backgroundColor: '#870e0e',
        padding: '16px',
        color: '#FFFAEE',
        fontWeight: '600',
        fontSize: '22px',
    },
    iconTheme: {
        primary: '#870e0e',
        secondary: '#FFFAEE',
    },
};
