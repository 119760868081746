import { DatePicker } from '@HometreeEngineering/component-library';
import { addMonths } from 'date-fns';
import { useSalesPortalContext } from 'features/salesPortal/contexts/SalesPortalContext';
import { SalesPortalState } from 'features/salesPortal/types';
import { ReactComponent as DirectDebit } from '../../assets/logos/directDebit.svg';
import { ReactComponent as MasterCard } from '../../assets/logos/mastercard.svg';
import { ReactComponent as Visa } from '../../assets/logos/visa.svg';
import { PromoReferralCode } from '../PromoReferralCode';
import Radio from '../Radio';
import styles from './index.module.scss';

export const PaymentInformationForm = () => {
    const {
        state: { payment: paymentState },
        dispatch,
    } = useSalesPortalContext();

    const handleDateChange = (date: Date) => {
        dispatch({
            type: 'setPayment',
            data: {
                startDate: date,
            },
        });
    };

    const handlePaymentMethodChange = (value: SalesPortalState['payment']['method']) => {
        dispatch({
            type: 'setPayment',
            data: {
                method: value,
            },
        });
    };

    return (
        <div className={styles.paymentInformationForm}>
            <form className={styles.formContainer}>
                <div>
                    <DatePicker
                        id="HomeCareCover_Checkout_Payment_ChangeStartDate"
                        dataTestId="datepicker"
                        label="Cover plan start date"
                        onChange={handleDateChange}
                        value={paymentState.startDate}
                        minDate={new Date()}
                        maxDate={addMonths(new Date(), 3)}
                    />

                    <div className={styles.paymentMethodList} data-testid="payment-method-list">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>Payment method</label>
                        <ul>
                            <li>
                                <Radio
                                    id="Payment_SelectTypeDD"
                                    onChange={() => handlePaymentMethodChange('directDebit')}
                                    checked={paymentState.method === 'directDebit'}
                                    name="directDebit"
                                >
                                    <div className={styles.eachPaymentMethod}>
                                        <span>Direct Debit</span>
                                        <div className={styles.directDebitLogo}>
                                            <DirectDebit />
                                        </div>
                                    </div>
                                </Radio>
                            </li>
                            <li>
                                <Radio
                                    id="Payment_SelectTypeCC"
                                    onChange={() => handlePaymentMethodChange('cardPayment')}
                                    checked={paymentState.method === 'cardPayment'}
                                    name="cardPayment"
                                    disabled
                                >
                                    <div className={styles.eachPaymentMethod}>
                                        <span>Card Payment</span>
                                        <div>
                                            <Visa />
                                            <MasterCard />
                                        </div>
                                    </div>
                                </Radio>
                            </li>
                        </ul>
                    </div>
                </div>
                <PromoReferralCode />
            </form>
        </div>
    );
};
