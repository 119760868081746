import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'api/authentication/types';

interface Product {
    name: string;
}

export interface Package {
    id: number;
    name: string;
    active?: number | null;
    author?: string | null;
    public?: number | null;
    type_id?: number | null;
    cb_plan_id?: string | null;
    meta_title?: string | null;
    description?: string | null;
    product_ids?: number[];
    date_created?: string | null;
    is_insurance?: number | null;
    date_modified?: string | null;
    meta_description?: string | null;
}

export interface PackageWithRelations extends Package {
    relations: { products: Product[] };
}

export interface Packages {
    data: Package[];
}
export interface PackagesWithRelations {
    data: PackageWithRelations[];
}

export const productSchema: JSONSchemaType<Product[]> = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            name: { type: 'string' },
        },
        required: [],
    },
};

export const packageSchema: JSONSchemaType<Package> = {
    type: 'object',
    properties: {
        id: { type: 'number' },
        name: { type: 'string' },
        active: { type: 'number', nullable: true },
        author: { type: 'string', nullable: true },
        public: { type: 'number', nullable: true },
        type_id: { type: 'number', nullable: true },
        cb_plan_id: { type: 'string', nullable: true },
        meta_title: { type: 'string', nullable: true },
        description: { type: 'string', nullable: true },
        product_ids: {
            type: 'array',
            items: { type: 'number', nullable: true },
            nullable: true,
        },
        date_created: { type: 'string', nullable: true },
        is_insurance: { type: 'number', nullable: true },
        date_modified: { type: 'string', nullable: true },
        meta_description: { type: 'string', nullable: true },
    },
    required: ['id', 'name'],
};

export type GetPackagesResponse = PandoraErrorResponse | PackagesWithRelations;

export const packagesSchema: JSONSchemaType<GetPackagesResponse> = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: { type: 'number' },
                    name: { type: 'string' },
                    active: { type: 'number', nullable: true },
                    author: { type: 'string', nullable: true },
                    public: { type: 'number', nullable: true },
                    type_id: { type: 'number', nullable: true },
                    cb_plan_id: { type: 'string', nullable: true },
                    meta_title: { type: 'string', nullable: true },
                    description: { type: 'string', nullable: true },
                    product_ids: {
                        type: 'array',
                        items: { type: 'number', nullable: true },
                        nullable: true,
                    },
                    date_created: { type: 'string', nullable: true },
                    is_insurance: { type: 'number', nullable: true },
                    date_modified: { type: 'string', nullable: true },
                    meta_description: { type: 'string', nullable: true },
                    relations: {
                        type: 'object',
                        properties: {
                            products: productSchema,
                        },
                        required: [],
                        additionalProperties: true,
                    },
                },
                required: ['id', 'name', 'relations'],
            },
        },
    },
    required: ['data'],
};
export const getPackageResponseSchema: JSONSchemaType<GetPackagesResponse> = {
    type: 'object',
    anyOf: [packagesSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};
