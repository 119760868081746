import { Button, DatePicker, Dropdown, TextInput } from '@HometreeEngineering/component-library';
import { Contract } from 'api/contract/types';
import { usePackagesQuery } from 'api/packages/usePackagesQuery';
import { addDays, startOfDay, subYears } from 'date-fns';
import {
    UpdatePropertyDetailsRequestPayload,
    updatePropertyDetails,
} from 'features/salesPortal/api/updatePropertyDetails';
import {
    generateBoilerAgeOptions,
    generateBoilerMakeOptions,
    generateNextServiceDateOptions,
} from 'features/salesPortal/utils/getBoilerFormOptions';
import { packageContainsLGSR } from 'features/salesPortal/utils/packageContainsLGSR';
import { useState } from 'react';
import styles from './index.module.scss';

interface Props {
    contractData: Contract;
}

export const BoilerInformationForm = ({ contractData }: Props) => {
    const { isLoading: isLoadingPackage, data: packagesData } = usePackagesQuery({
        packageId: contractData.package_id,
        params: { expand: 'products' },
    });

    const [isSuccess, setIsSuccess] = useState(false); // TODO rename this ahhh state name

    const [formState, setFormState] = useState({
        boilerMake: '',
        boilerOtherMake: '',
        boilerModel: '',
        boilerAge: '',
        nextServiceDate: '',
        lastServiceDate: '',
    });

    const [formErrors, setFormErrors] = useState({
        boilerMake: '',
        boilerOtherMake: '',
        boilerModel: '',
        boilerAge: '',
        nextServiceDate: '',
        lastServiceDate: '',
        submitResponse: '',
    });

    const handleOnChange = (key: keyof typeof formState) => (value: string | number) => {
        setFormState((p) => ({
            ...p,
            [key]: value.toString(),
        }));
    };

    const sendBoilerInfo = async (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
        e!.preventDefault();
        const target = e!.target as HTMLButtonElement;

        setFormErrors((prev) => ({
            ...prev,
            boilerMake: '',
            boilerOtherMake: '',
            lastServiceDate: '',
        }));

        if (
            packageContainsLGSR(packagesData?.data[0].relations.products || []) &&
            !formState.lastServiceDate
        ) {
            setFormErrors((prev) => ({
                ...prev,
                lastServiceDate: 'Please select a valid date',
            }));
            return;
        }

        if (!contractData.id || !contractData.property_id) {
            setFormErrors((prev) => ({
                ...prev,
                submitResponse: 'Contract ID or property ID undefined in state',
            }));
            return;
        }

        target.disabled = true;
        target.innerText = 'Sending...';

        const uploadBoilerInfoPayload: UpdatePropertyDetailsRequestPayload = {
            contract_id: contractData.id,
            ...(formState.boilerMake && {
                boiler_make: formState.boilerOtherMake || formState.boilerMake,
            }),
            ...(formState.boilerModel && { boiler_model: formState.boilerModel }),
            ...(formState.boilerAge && { boiler_age: formState.boilerAge }),
        };

        if (
            packageContainsLGSR(packagesData?.data[0].relations.products || []) &&
            typeof formState.lastServiceDate !== 'undefined' &&
            formState.lastServiceDate !== ''
        ) {
            uploadBoilerInfoPayload.last_service = formState.lastServiceDate;
        } else if (
            typeof formState.nextServiceDate !== 'undefined' &&
            formState.nextServiceDate !== ''
        ) {
            uploadBoilerInfoPayload.next_service = formState.nextServiceDate;
        }

        await updatePropertyDetails(contractData.property_id, uploadBoilerInfoPayload);

        setIsSuccess(true);
    };

    if (isLoadingPackage) {
        return <h5>Loading next steps form...</h5>;
    }

    return (
        <div className={styles.boilerInformationForm}>
            <div className={styles.nextSteps}>
                <h5>What&apos;s next</h5>
                <p>
                    A welcome email has been sent to the customer&apos;s inbox, which includes the
                    policy details. (Remind them to check your spam folder, in case it&apos;s landed
                    there!)
                </p>
                <div>
                    <h5>Collect information about the customer&apos;s boiler</h5>
                    <p>
                        It&apos;s ok if the customer doesn&apos;t know everything right now. This
                        information helps us prepare for your future claims, and does not alter the
                        price of the cover plan.
                    </p>
                </div>
            </div>
            {!isSuccess ? (
                <form className={styles.boilerInfoForm}>
                    <Dropdown
                        label="Boiler make"
                        id="Boiler make"
                        testId="boilerMakeDropdown"
                        selectedOption={formState.boilerMake}
                        options={generateBoilerMakeOptions()}
                        onSelectedOptionChange={(value) => {
                            setFormState((p) => ({
                                ...p,
                                boilerMake: value.toString(),
                                boilerOtherMake: '',
                            }));
                        }}
                        error={formErrors.boilerMake}
                    />

                    {formState.boilerMake === 'other' && (
                        <TextInput
                            label="Specify boiler make"
                            id="otherBoilerMake"
                            testId="otherBoilerMake"
                            placeholder="Specify your boiler make"
                            value={formState.boilerOtherMake}
                            onChange={handleOnChange('boilerOtherMake')}
                            error={formErrors.boilerOtherMake}
                        />
                    )}

                    <TextInput
                        label="Boiler model"
                        id="boilerModelInput"
                        testId="boilerModelInput"
                        placeholder="Please enter..."
                        value={formState.boilerModel}
                        onChange={handleOnChange('boilerModel')}
                        error={formErrors.boilerModel}
                    />

                    <Dropdown
                        label="Boiler age"
                        id="Boiler age"
                        testId="boilerAgeDropdown"
                        selectedOption={formState.boilerAge}
                        options={generateBoilerAgeOptions()}
                        onSelectedOptionChange={handleOnChange('boilerAge')}
                        error={formErrors.boilerAge}
                    />

                    {packageContainsLGSR(packagesData?.data[0].relations.products || []) ? (
                        <div>
                            <DatePicker
                                id="lastServiceDatePicker"
                                dataTestId="lastServiceDatePicker"
                                label="When does your Landlord Gas Safety Record expire?"
                                value={
                                    formState.lastServiceDate
                                        ? new Date(formState.lastServiceDate)
                                        : new Date()
                                }
                                onChange={(date) =>
                                    handleOnChange('lastServiceDate')(
                                        startOfDay(date).toISOString()
                                    )
                                }
                                error={formErrors.lastServiceDate}
                                minDate={subYears(new Date(), 3)}
                                maxDate={addDays(new Date(), 366)}
                            />
                        </div>
                    ) : (
                        <Dropdown
                            label="Your boiler service will be scheduled for"
                            testId="nextServiceDropdown"
                            selectedOption={formState.nextServiceDate}
                            options={generateNextServiceDateOptions(
                                startOfDay(new Date(contractData.start_date) || new Date())
                            )}
                            onSelectedOptionChange={handleOnChange('nextServiceDate')}
                            error={formErrors.nextServiceDate}
                        />
                    )}

                    <p>
                        Your plan includes a boiler service, we carry them out between March and
                        September and close to the anniversary of your cover plan start date. This
                        helps us maximise our emergency response time during the busier winter
                        months.
                    </p>

                    {formErrors.submitResponse && (
                        <p className={styles.submitError}>
                            Hmm, something went wrong. Please report this to the assigned support
                            ranger.
                        </p>
                    )}
                    <Button customStyle={styles.submitBtn} onClick={(e) => sendBoilerInfo(e)}>
                        Submit
                    </Button>
                </form>
            ) : (
                <div>
                    {/* TODO redo this message and styles */}
                    <h2>Successfully sent boiler details</h2>
                </div>
            )}
        </div>
    );
};
