const POSTCODE_REGEX =
    '^([A-PR-UWYZ0-9a-pr-uwyz][A-HK-Y0-9a-hk-y][AEFGHJMNPRTUVXY0-9aeghjmnprtuvxy]?[ABEHMNPRUVWXY0-9abehmnprvwxy]?\\s?[0-9][ABD-HJLN-UW-Zabd-hjln-uw-z]{2}|GIR 0AA|gir 0aa)$';
const PHONE_NUMBER_REGEX = '^0(?:\\d\\s*){9,10}$';

const ALPHA_NUM_EXTENDED = "^[a-zA-Z\\d\\-_,/\\s\\.']+$";

export const isPostcode = (postcode: string): boolean => new RegExp(POSTCODE_REGEX).test(postcode);

export const isAlphaNumeric = (str: string): boolean => new RegExp(ALPHA_NUM_EXTENDED).test(str);

export const isPhoneNumber = (str: string): boolean =>
    new RegExp(PHONE_NUMBER_REGEX).test(str) && /^[0-9+\s]+$/.test(str);
