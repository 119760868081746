import { getAmplifyIdTokenFromCookie } from 'api/authentication';
import axios from 'axios';
import { SalesPortalState } from '../types';
import { mapRegisterCustomerPayload } from '../utils/mapRegisterCustomerPayload';
// import { validateRegisterCustomerBody } from 'utils/validateRegisterCustomerBody';

interface RegisterCustomerResponseJson {
    success: boolean;
    message?: string;
    contractId?: number;
    accountId?: number;
    propertyId?: number;
    errorCode?: string;
}

export const registerCustomer = async (
    state: SalesPortalState
): Promise<RegisterCustomerResponseJson> => {
    console.log(state, 'state');
    const registerCustomerPayload = mapRegisterCustomerPayload(state);
    const validatedBody = { valid: true };
    // const validatedBody = validateRegisterCustomerBody(registerCustomerPayload);

    if (validatedBody.valid === false) {
        return {
            // ...validatedBody,
            success: false,
        } as RegisterCustomerResponseJson;
    }

    const token = await getAmplifyIdTokenFromCookie();

    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/sales/v1/customer/create`,
        registerCustomerPayload,
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    );

    return { ...response.data } as RegisterCustomerResponseJson;
};
