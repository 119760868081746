import { Dropdown, InputButtonGroup } from '@HometreeEngineering/component-library';
import { useState } from 'react';
import { isPostcode } from 'utils/validation';
import styles from './index.module.scss';

interface PostcodeCheckResponse {
    covered: boolean;
    postcode: string;
    addresses: string[];
    latitude: number;
    longitude: number;
}
export interface Address {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    locality: string;
    town: string;
    county: string;
    postcode: string;
}

interface PostcodeFinderProps {
    // eslint-disable-next-line no-unused-vars
    onSelect: (address: Address) => void;
    onSearch: () => void;
    postcode: string;
    setPostcode: (value: string) => void;
}

function PostcodeFinder({
    onSelect,
    onSearch,
    postcode,
    setPostcode,
}: PostcodeFinderProps): JSX.Element {
    const [addresses, setAddresses] = useState<string[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState<string>('');
    const [error, setError] = useState('');

    const onClick = async () => {
        setError('');
        setAddresses([]);

        if (!isPostcode(postcode)) {
            setError('Please enter a valid postcode');
            return;
        }

        try {
            setIsSearching(true);
            const result = await fetch(
                `${process.env.REACT_APP_CHECKOUT_API_BASE_URL}/postcodes/pub/v1/pc`,
                {
                    method: 'POST',
                    headers: {
                        // Currently the postcode endpoint does not support cors properly
                        'Content-type': 'text/plain',
                    },
                    body: JSON.stringify({
                        action: 'processPostcode',
                        postcode,
                        productType: 'Homecover',
                    }),
                }
            );

            // TODO review what error to throw here. Something more useful
            if (!result.ok) throw new Error('Error getting addresses');

            const data = (await result.json()).data as PostcodeCheckResponse;

            if (data.addresses.length > 0) {
                setAddresses(data.addresses.map((address) => `${address}, ${data.postcode}`));
                onSearch();
            } else {
                setError('No addresses found');
            }
        } catch (err) {
            setError('Error finding addresses');
        } finally {
            setIsSearching(false);
        }
    };

    const createAddressObject = (address: string): Address | Record<string, never> => {
        const addressArray = address.split(',');

        if (addressArray.length !== 8) return {};

        const addressObject: Address = {
            line1: addressArray[0].trim(),
            line2: addressArray[1].trim(),
            line3: addressArray[2].trim(),
            line4: addressArray[3].trim(),
            locality: addressArray[4].trim(),
            town: addressArray[5].trim(),
            county: addressArray[6].trim(),
            postcode: addressArray[7].trim(),
        };

        return addressObject;
    };

    const onSelectAddress = (value: string | number) => {
        const address = value.toString();
        const addressObject = createAddressObject(address);

        // Check if of type Address
        if (addressObject.line1) {
            onSelect(addressObject as Address);
            setSelectedAddress(address);
        }
    };

    let addressOptions = addresses
        .map((address) => {
            return {
                value: address,
                displayValue: address
                    .split(',')
                    .filter((val) => /\S/.test(val))
                    .join(),
                disabled: false,
            };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    addressOptions = [
        { value: 'placeholder', displayValue: 'Select full address', disabled: true },
    ].concat(addressOptions);

    return (
        <div className={styles.postcodeFinder} data-testid="postcodeFinder">
            <InputButtonGroup
                id="mockInputButtonGroup"
                onChange={(e) => setPostcode(e.target.value.toUpperCase())}
                onClick={onClick}
                buttonText="Find"
                value={postcode}
                placeholder="Enter a valid postcode"
                disabled={isSearching}
                error={error}
            />

            {addresses.length > 0 && (
                <Dropdown
                    customStyle={styles.dropdown}
                    onSelectedOptionChange={onSelectAddress}
                    selectedOption={selectedAddress}
                    options={addressOptions}
                />
            )}
        </div>
    );
}

export default PostcodeFinder;
