import { PackageKey, SelectConfig } from 'features/salesPortal/types';
import { ReactComponent as Dash } from '../../assets/dash.svg';
import { ReactComponent as Check } from '../../assets/tick.svg';

// Bit of a mess it needs rethinking in phase 2.
export const selectConfig: Record<string, SelectConfig> = {
    insurance: {
        name: 'Insurance',
        pricebook_id: 64,
        packages: [
            {
                id: 13,
                key: PackageKey.Boiler,
                customer_type: 'homeowner',
            },
            {
                id: 14,
                key: PackageKey.Heating,
                customer_type: 'homeowner',
            },
            {
                id: 15,
                key: PackageKey.Home,
                customer_type: 'homeowner',
            },
            {
                id: 29,
                key: PackageKey.HomePlus,
                customer_type: 'homeowner',
            },
            {
                id: 16,
                key: PackageKey.Boiler,
                customer_type: 'landlord',
            },
            {
                id: 17,
                key: PackageKey.Heating,
                customer_type: 'landlord',
            },
            {
                id: 18,
                key: PackageKey.Home,
                customer_type: 'landlord',
            },
            {
                id: 30,
                key: PackageKey.HomePlus,
                customer_type: 'landlord',
            },
        ],
    },
    ctm: {
        name: 'Compare the Market',
        source: 'ctm',
        pricebook_id: 63,
        packages: [
            {
                id: 10,
                key: PackageKey.BoilerEssentials,
                customer_type: 'homeowner',
            },
            {
                id: 1,
                key: PackageKey.Boiler,
                customer_type: 'homeowner',
            },
            {
                id: 2,
                key: PackageKey.Heating,
                customer_type: 'homeowner',
            },
            {
                id: 3,
                key: PackageKey.Home,
                customer_type: 'homeowner',
            },
            {
                id: 4,
                key: PackageKey.Boiler,
                customer_type: 'landlord',
            },
            {
                id: 5,
                key: PackageKey.Heating,
                customer_type: 'landlord',
            },
            {
                id: 6,
                key: PackageKey.Home,
                customer_type: 'landlord',
            },
        ],
    },
    soEnergy: {
        name: 'SoEnergy',
        source: 'SoEnergy',
        pricebook_id: 51,
        packages: [
            {
                id: 11,
                key: PackageKey.HeatingEssentials,
                customer_type: 'homeowner',
            },
            {
                id: 1,
                key: PackageKey.Boiler,
                customer_type: 'homeowner',
            },
            {
                id: 2,
                key: PackageKey.Heating,
                customer_type: 'homeowner',
            },
            {
                id: 3,
                key: PackageKey.Home,
                customer_type: 'homeowner',
            },
            {
                id: 4,
                key: PackageKey.Boiler,
                customer_type: 'landlord',
            },
            {
                id: 5,
                key: PackageKey.Heating,
                customer_type: 'landlord',
            },
            {
                id: 6,
                key: PackageKey.Home,
                customer_type: 'landlord',
            },
        ],
    },
    fosterDenovo: {
        name: 'Foster Denovo',
        source: 'Foster Denovo',
        pricebook_id: 51,
        packages: [
            {
                id: 13,
                key: PackageKey.Boiler,
                customer_type: 'homeowner',
            },
            {
                id: 14,
                key: PackageKey.Heating,
                customer_type: 'homeowner',
            },
            {
                id: 15,
                key: PackageKey.Home,
                customer_type: 'homeowner',
            },
            {
                id: 29,
                key: PackageKey.HomePlus,
                customer_type: 'homeowner',
            },
            {
                id: 16,
                key: PackageKey.Boiler,
                customer_type: 'landlord',
            },
            {
                id: 17,
                key: PackageKey.Heating,
                customer_type: 'landlord',
            },
            {
                id: 18,
                key: PackageKey.Home,
                customer_type: 'landlord',
            },
            {
                id: 30,
                key: PackageKey.HomePlus,
                customer_type: 'landlord',
            },
        ],
    },
};

interface Item {
    name: string;
    popover: string;
    content: Record<PackageKey, React.ReactNode | string>;
    showIn: Array<'homeowner' | 'landlord'>;
}

export const items: Item[] = [
    {
        name: 'Annual Boiler Service',
        popover:
            'A yearly visit to check your boiler and central heating are safe and in good working order. If we find any faults we will fix them during your service, or come back and fix them later.',
        content: {
            boilerEssentials: 'First year only',
            boiler: <Check />,
            heatingEssentials: <Check />,
            heating: <Check />,
            home: <Check />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
    {
        name: 'Landlord Gas Safety Record (LGSR)',
        popover: 'Includes an inspection of any gas appliances you have in the home',
        content: {
            boilerEssentials: <Dash />,
            boiler: <Check />,
            heatingEssentials: <Dash />,
            heating: <Check />,
            home: <Check />,
            homePlus: <Check />,
        },
        showIn: ['landlord'],
    },
    {
        name: 'Gas boiler, flue & controls',
        popover:
            'Repairs to your boiler including issues with any thermostats, programmers, valves or pumps. Includes all parts and labour.',
        content: {
            boilerEssentials: <Check />,
            boiler: <Check />,
            heatingEssentials: 'Emergencies',
            heating: <Check />,
            home: <Check />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
    {
        name: 'Central Heating',
        popover:
            'Repairs to your radiators, cylinders, or valves related to your central heating. Includes all parts and labour.',
        content: {
            boilerEssentials: <Dash />,
            boiler: <Dash />,
            heatingEssentials: 'Emergencies',
            heating: <Check />,
            home: <Check />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
    {
        name: 'Plumbing',
        popover:
            'Repairs to your plumbing like burst pipes, a leaking stopcock or a broken flush. Includes all parts and labour.',
        content: {
            boilerEssentials: <Dash />,
            boiler: <Dash />,
            heatingEssentials: <Dash />,
            heating: <Dash />,
            home: <Check />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
    {
        name: 'Drains',
        popover:
            'Unblocking blocked drains, sinks and blocked toilets. Includes all parts and labour.',
        content: {
            boilerEssentials: <Dash />,
            boiler: <Dash />,
            heatingEssentials: <Dash />,
            heating: <Dash />,
            home: <Check />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
    {
        name: 'Home Electrics',
        popover:
            'Repairs to mains electrical system including fuse boxes, light fittings, light switches, smoke alarms and doorbells. Includes all parts and labour.',
        content: {
            boilerEssentials: <Dash />,
            boiler: <Dash />,
            heatingEssentials: <Dash />,
            heating: <Dash />,
            home: <Check />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
    {
        name: 'Home Security',
        popover:
            'Boarding up of doors & windows to secure home, gain access, replace lost keys & repair external locks.',
        content: {
            boilerEssentials: <Dash />,
            boiler: <Dash />,
            heatingEssentials: <Dash />,
            heating: <Dash />,
            home: <Dash />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
    {
        name: 'Pest Control',
        popover:
            'Treatment for house/field mice and brown/black rats inside the home – and wasps & hornets inside & outside (garden, outbuildings or garage).',
        content: {
            boilerEssentials: <Dash />,
            boiler: <Dash />,
            heatingEssentials: <Dash />,
            heating: <Dash />,
            home: <Dash />,
            homePlus: <Check />,
        },
        showIn: ['homeowner', 'landlord'],
    },
];

export const packageContent = [
    {
        name: PackageKey.BoilerEssentials,
        title: 'Your Boiler Essentials',
        subtitle: 'with Compare the Market',
        ipid_link: '',
        included: [
            'An annual boiler service',
            'Gas boiler, flue & controls',
            'Gas supply pipe (connects your gas appliances to your meter)',
            'Emergencies where you have no heating or hot water or if there is an uncontrollable water leak from your boiler',
        ],
        excluded: [
            'Wider central heating system, such as radiators, cylinders, pipes',
            'Plumbing & water supply pipes',
            'Unblocking of drains & waste pipes',
            'Removal of sludge, scale or repairing damage caused by this',
            'Intermittent or non-emergency faults to your system',
        ],
    },
    {
        name: PackageKey.Boiler,
        title: 'Your Boiler',
        subtitle: 'Our basic cover',
        ipid_link:
            'https://www.hometree.co.uk/wp-content/uploads/2023/08/Your-Boiler-IPID-Millennium-v2.pdf',
        included: [
            'An annual boiler service',
            'Gas boiler, flue & controls',
            'Gas supply pipe (connects your gas appliances to your meter)',
            'Free replacement of your boiler if Hometree deems it beyond Economic Repair and it’s less than 7 years old',
        ],
        excluded: [
            'Wider central heating system, such as radiators, cylinders, pipes',
            'Plumbing & water supply pipes',
            'Unblocking of drains & waste pipes',
            'Mains electrical wiring system, such as circuits, fuse box, sockets, light fittings',
            'Removal of sludge, scale or repairing damage caused by this',
            'Showers and taps',
            'Bathroom items, such as sinks, toilet bowls and other ceramic items',
        ],
    },
    {
        name: PackageKey.HeatingEssentials,
        title: 'Your Heating Essentials',
        subtitle: '',
        ipid_link: '',
        included: [
            'An annual boiler service',
            'Gas boiler, flue & controls',
            'Wider central heating system, such as radiators, cylinders, pipes',
            'Gas supply pipe (connects your gas appliances to your meter)',
            '15% discount off the price of a new boiler if we can’t fix it',
            'Please note: this product only covers emergencies where you have no heating or hot water or if there is an uncontrollable water leak from your boiler',
        ],
        excluded: [
            'Intermittent or non-emergency faults to your system',
            'Removal of sludge, scale or repairing damage caused by this',
            'Showers and taps',
            'Bathroom items, such as sinks, toilet bowls and other ceramic items',
            'Household appliances, such as fridges and washing machines',
            'Mains electrical wiring system, such as circuits, fuse box, sockets, light fittings',
            'Unblocking of drains & waste pipes',
        ],
    },
    {
        name: PackageKey.Heating,
        title: 'Your Heating',
        subtitle: 'Comprehensive cover',
        ipid_link:
            'https://www.hometree.co.uk/wp-content/uploads/2023/08/Your-Heating-IPID-Millennium-v2.pdf',
        included: [
            'An annual boiler service',
            'Gas boiler, flue & controls',
            'Wider central heating system, such as radiators, cylinders, pipes',
            'Gas supply pipe (connects your gas appliances to your meter)',
            'Free replacement of your boiler if Hometree deems it beyond Economic Repair and it’s less than 7 years old',
        ],
        excluded: [
            'Plumbing & water supply pipes',
            'Unblocking of drains & waste pipes',
            'Mains electrical wiring system, such as circuits, fuse box, sockets, light fittings',
            'Removal of sludge, scale or repairing damage caused by this',
            'Showers and taps',
            'Bathroom items, such as sinks, toilet bowls and other ceramic items',
        ],
    },
    {
        name: PackageKey.Home,
        title: 'Your Home',
        subtitle: 'Our advanced cover',
        ipid_link:
            'https://www.hometree.co.uk/wp-content/uploads/2023/08/Your-Home-IPID-Millennium-v2.pdf',
        included: [
            'An annual boiler service',
            'Gas boiler, flue & controls',
            'Wider central heating system, such as radiators, cylinders, pipes',
            'Plumbing & water supply pipes',
            'Unblocking of drains & waste pipes',
            'Mains electrical wiring system, such as circuits, fuse box, sockets, light fittings',
            'Gas supply pipe (connects your gas appliances to your meter)',
            'Free replacement of your boiler if Hometree deems it beyond Economic Repair and it’s less than 7 years old',
        ],
        excluded: [
            'Removal of sludge, scale or repairing damage caused by this',
            'Showers and taps',
            'Bathroom items, such as sinks, toilet bowls and other ceramic items',
            'Household appliances, such as fridges and washing machines',
        ],
    },
    {
        name: PackageKey.HomePlus,
        title: 'Your Home Plus',
        subtitle: 'Our maximum cover',
        ipid_link:
            'https://www.hometree.co.uk/wp-content/uploads/2023/10/Your-Home-Plus-IPID-Millenium-v2.pdf',
        included: [
            'An annual boiler service',
            'Gas boiler, flue & controls',
            'Wider central heating system, such as radiators, cylinders, pipes',
            'Plumbing & water supply pipes',
            'Unblocking of drains & waste pipes',
            'Mains electrical wiring system, such as circuits, fuse box, sockets, light fittings',
            'Pest control: rats, house & field mice (inside home), wasps & hornets nests (inside home, garden, outbuildings & garage)',
            'Replacement of broken locks on external doors / windows & gain access if locked out',
            'Boarding up of broken external doors & windows',
            'Gas supply pipe (connects your gas appliances to your meter)',
            'Taps inside home',
            'Free replacement of your boiler if Hometree deems it beyond Economic Repair and it’s less than 7 years old',
        ],
        excluded: [
            'Removal of sludge, scale or repairing damage caused by this',
            'Bathroom items, such as sinks, toilet bowls and other ceramic items',
            'Household appliances, such as fridges and washing machines',
            'Showers',
        ],
    },
];
