import { PromoCode, SalesPortalState } from '../types';

export const calculatelDiscountAmount = (state: SalesPortalState) => {
    let discount = 0;
    const { promocode, monthly_payment_amount: monthlyPaymentAmount } = state.plan;

    if (promocode) {
        // Discount.value is an integer
        if (promocode.type === PromoCode.PERCENTAGE) {
            discount = Math.ceil(monthlyPaymentAmount * (promocode.value / 100));
        } else if (promocode.type === PromoCode.VALUE) {
            // Value discounts don't mention how much it takes off, but may mislead
            // monthly payment users
            discount = (promocode.value * 100) / 12;
        }
    }

    if (discount >= monthlyPaymentAmount) {
        discount = monthlyPaymentAmount;
    }

    return discount;
};
