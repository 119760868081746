import { Contract, Log } from 'api/contract/types';
import { useEffect, useState } from 'react';
import { Status } from '../api/types';
import { convertContractBillingType } from '../constants';
import { HistoryKey } from '../types/History';
import { getPaymentAmountForBillingType } from '../utils/getAmount';
import { useContractStatusesQuery } from './useContractStatusesQuery';

export const useFieldHistory = (contract: Contract, key: HistoryKey) => {
    const { data: statuses } = useContractStatusesQuery(key);
    const [history, setHistory] = useState<
        {
            author: string;
            value: any;
            date: string;
            current: boolean;
        }[]
    >([]);

    useEffect(() => {
        if (contract.log && contract.log.length > 0) {
            let lastValue: any = '';
            const historyArray: any[] = [];

            const wantedHistory = contract.log.filter((item) => {
                if (item[key] !== lastValue) {
                    lastValue = item[key];
                    return true;
                }
                return false;
            });

            const getValue = (item: Log | Contract, itemKey: HistoryKey) =>
                itemKey === 'status'
                    ? statuses?.data?.find((s: Status) => s.id === item[itemKey])?.name
                    : item[itemKey];

            const getPaymentAmountValue = (item: Log | Contract) =>
                `${getPaymentAmountForBillingType(
                    item.payment_amount || 0,
                    item.billing_type || 0
                )} ${item.billing_type && `- ${convertContractBillingType[item.billing_type]}`}`;

            wantedHistory.forEach((item) => {
                historyArray.push({
                    author: item.last_modified_by ? item.last_modified_by : item.author,
                    value:
                        key === 'payment_amount'
                            ? getPaymentAmountValue(item)
                            : getValue(item, key),
                    date: item.date_modified,
                    current: false,
                });
            });

            if (historyArray.length > 0) {
                const lastHistoryItem = historyArray[historyArray.length - 1];
                if (
                    lastHistoryItem.value !== contract[key] &&
                    getValue(contract, key) !== lastHistoryItem.value
                ) {
                    historyArray.push({
                        value:
                            key === 'status'
                                ? getValue(contract, key)
                                : getPaymentAmountValue(contract),
                        author: contract.last_modified_by || '',
                        date: contract.date_modified,
                        current: true,
                    });
                } else if (historyArray.length === 1) {
                    setHistory([]);
                    return;
                } else {
                    historyArray[historyArray.length - 1].current = true;
                }
                setHistory(historyArray.slice().reverse());
            } else {
                setHistory([]);
            }
        } else {
            setHistory([]);
        }
    }, [contract, key, statuses?.data]);

    return history;
};
