import { Button, TextInput } from '@HometreeEngineering/component-library';
import { Contract } from 'api/contract/types';
import { HistoryIcon, NewTabIcon } from 'assets/pandoraIcons';
import { UpdateContractInput } from 'features/contract/api/updateContract';
import { useUpdateContractMutation } from 'features/contract/hooks/useUpdateContractMutation';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { getMandatoryEnvVar } from 'utils/env';
import toastConfig from 'utils/toastConfig';
import ContractHistory from '../../ContractHistory';
import WarningModal from '../../ContractModals/WarningModal';
import styles from './index.module.scss';

interface Props {
    data: Contract;
}

const cbURL = getMandatoryEnvVar('REACT_APP_CB_URL');

const ChargeBee = ({ data }: Props) => {
    const updateContractMutation = useUpdateContractMutation();
    const [isModalOpen, setModalOpen] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [cbChanges, setCbChanges] = useState<{
        cb_subscription_id?: string;
        cb_customer_id?: string;
    }>({});

    const [isHistoryOpen, setIsHistoryOpen] = useState<{
        cb_subscription_id: boolean;
        cb_customer_id: boolean;
    }>({
        cb_subscription_id: false,
        cb_customer_id: false,
    });

    const handleCancel = () => {
        setModalOpen(false);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const dataToSubmit = { id: data.id, ...cbChanges } as UpdateContractInput;

            const res = await updateContractMutation.mutateAsync(dataToSubmit);
            setModalOpen(false);
            toast.success(res.data?.message, toastConfig);
            setCbChanges({});
            setCanSubmit(false);
        } catch (error) {
            toast.error((error as Error)?.message || 'Error updating account');
        }
    };

    const onEdit = (e: string, key: string) => {
        const newData = { ...cbChanges, [key]: e };
        setCbChanges(newData);
        setCanSubmit(true);
    };

    const onCancel = () => {
        setCanSubmit(false);
        setCbChanges({});
    };

    const getChangeText = () => {
        let change = '';
        Object.entries(cbChanges).forEach((val) => {
            if (val[0] === 'cb_customer_id' && val[1] !== data.cb_customer_id) {
                change += ` Chargebee Customer ID will change to ${val[1]}.`;
            }

            if (val[0] === 'cb_subscription_id' && val[1] !== data.cb_subscription_id) {
                change += ` Chargebee Subscription ID will change to ${val[1]}.`;
            }
        });
        return <strong>{change} </strong>;
    };

    return (
        <div>
            <div className={styles.paymentHeader}>
                <h5>Chargebee</h5>
            </div>
            <div className={styles.paymentMethodContainer}>
                <div className={styles.paymentMethods}>
                    <div className={styles.fields}>
                        <TextInput
                            id="cbSubscription"
                            label="CHARGEBEE SUBSCRIPTION"
                            placeholder={data.cb_subscription_id || '-'}
                            value={cbChanges.cb_subscription_id ?? (data.cb_subscription_id || '')}
                            icon={
                                <>
                                    <HistoryIcon
                                        title="history"
                                        role="button"
                                        className={`${styles.historyIcon} ${
                                            isHistoryOpen.cb_subscription_id ? styles.open : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsHistoryOpen({
                                                ...isHistoryOpen,
                                                cb_subscription_id:
                                                    !isHistoryOpen.cb_subscription_id,
                                            });
                                        }}
                                    />
                                    <a
                                        href={`${cbURL}/subscriptions/${
                                            data.cb_subscription_id || ''
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                        aria-labelledby="cbSubscription"
                                    >
                                        <NewTabIcon className={styles.icon} />
                                    </a>
                                </>
                            }
                            onChange={(e) => {
                                onEdit(e, 'cb_subscription_id');
                            }}
                        />
                        <ContractHistory
                            data={data}
                            isOpen={isHistoryOpen.cb_subscription_id}
                            historyKey="cb_subscription_id"
                            alignment={styles.historyAlignment}
                        />
                    </div>
                    <div className={styles.fields}>
                        <TextInput
                            id="cbCustomer"
                            label="CHARGEBEE CUSTOMER"
                            placeholder={data.cb_customer_id || '-'}
                            value={cbChanges.cb_customer_id ?? (data.cb_customer_id || '')}
                            icon={
                                <>
                                    <HistoryIcon
                                        title="history"
                                        role="button"
                                        className={`${styles.historyIcon} ${
                                            isHistoryOpen.cb_customer_id ? styles.open : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsHistoryOpen({
                                                ...isHistoryOpen,
                                                cb_customer_id: !isHistoryOpen.cb_customer_id,
                                            });
                                        }}
                                    />
                                    <a
                                        href={`${cbURL}/customers/${data.cb_customer_id || ''}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        aria-labelledby="cbCustomer"
                                    >
                                        <NewTabIcon className={styles.icon} />
                                    </a>
                                </>
                            }
                            onChange={(e) => {
                                onEdit(e, 'cb_customer_id');
                            }}
                        />
                        <ContractHistory
                            data={data}
                            isOpen={isHistoryOpen.cb_customer_id}
                            historyKey="cb_customer_id"
                            alignment={styles.historyAlignment}
                        />
                    </div>
                </div>
                <div className={styles.paymentButtons}>
                    <Button onClick={onCancel} variant="outlined" isDisabled={!canSubmit}>
                        Cancel
                    </Button>
                    <Button onClick={() => setModalOpen(true)} isDisabled={!canSubmit}>
                        Confirm
                    </Button>
                </div>
            </div>
            {isModalOpen ? (
                <WarningModal
                    isOpen={isModalOpen}
                    setIsOpen={setModalOpen}
                    warningMessage={
                        <span>
                            You are about to make changes to this customer&apos;s Chargebee account.
                            {getChangeText()} Please cancel if this was made in error.
                        </span>
                    }
                    onCancel={handleCancel}
                    onSave={handleSubmit}
                />
            ) : null}
        </div>
    );
};

export default ChargeBee;
