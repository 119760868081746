import axios from 'axios';

export interface UpdatePropertyDetailsRequestPayload {
    contract_id: number;
    boiler_make?: string;
    boiler_model?: string;
    boiler_age?: string;
    parking_permit_needed?: boolean;
    next_service?: string;
    last_service?: string;
}

export const updatePropertyDetails = async (
    propertyId: number,
    payload: UpdatePropertyDetailsRequestPayload
) => {
    const response = await axios.patch(
        `${process.env.REACT_APP_CHECKOUT_API_BASE_URL}/properties-public/pub/v1/properties/${propertyId}`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return { ...response.data } as {
        message: string;
        boilerMakeId: number | null;
        boilerModelId: number | null;
    };
};
