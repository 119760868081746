import { Amplify, Auth } from 'aws-amplify';

const stageToRedirectSignIn: { [key: string]: string } = {
    local: 'http://localhost:3000/login',
    dev: 'https://claims.pandora.dev.hometree.co.uk/login',
    stage: 'https://claims.pandora.stage.hometree.co.uk/login',
    prod: 'https://claims.pandora.hometree.co.uk/login',
};

const getRedirectSign = () =>
    stageToRedirectSignIn[process.env.REACT_APP_STAGE] || stageToRedirectSignIn.local;

Amplify.configure({
    Auth: {
        region: 'eu-west-2',
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_USER_POOL_DOMAIN,
            redirectSignIn: getRedirectSign(),
            responseType: 'token',
            scope: ['email', 'openid', 'phone', 'profile'],
        },
    },
});

export { Auth };
