import { Alert, AlertTitle, Tooltip } from '@mui/material';
import { GridCellValue, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { SkeletonLoader } from 'components/loading/SkeletonLoader';
import { useToggle } from 'hooks/useToggle';
import { useParams } from 'react-router';

import { SecondaryButton } from 'components/buttons';
import { DatabaseJobs } from 'features/claim/api/jobs/type';
import { Table } from '../Table';
import { CreateNewJobModal } from './CreateNewJobModal';
import styles from './index.module.scss';

interface ColumnMetaData {
    headerName?: string;
    width?: number;
    type?: string;
    renderCell?: any;
}

const { REACT_APP_SKEDULO_DOMAIN } = process.env;

const generateSkeduloLink = (jobId: string) => {
    return `${REACT_APP_SKEDULO_DOMAIN}/job/${jobId}`;
};

const columnsProps: { [key: string]: ColumnMetaData } = {
    sk_job_name: {
        headerName: 'Name',
        renderCell: (params: GridRenderCellParams<string>) => (
            <a href={generateSkeduloLink(params.row.sk_job_id)} target="_blank" rel="noreferrer">
                {params.value}
            </a>
        ),
    },
    sk_job_status: {
        headerName: 'Status',
    },
    summary: {
        headerName: 'Description',
        width: 300,
        renderCell: (params: GridRenderCellParams<string>) => (
            <Tooltip title={params.row.summary}>
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {params.row.summary}
                </span>
            </Tooltip>
        ),
    },
    sk_urgency: {
        headerName: 'Urgency',
    },
    sk_visit_type: {
        headerName: 'Visit Type',
    },
    sk_created_date: {
        headerName: 'Created Date',
        type: 'date',
    },
    sk_actual_start: {
        headerName: 'Actual Start Date',
        type: 'date',
    },
    sk_resource_name: {
        headerName: 'Engineer',
        renderCell: (params: GridRenderCellParams<string>) => (
            <a
                href={`${REACT_APP_SKEDULO_DOMAIN}/resources/${params.row.sk_resource_id}/details`}
                target="_blank"
                rel="noreferrer"
            >
                {params.value}
            </a>
        ),
    },
};

const generateColumns = (data: DatabaseJobs) => {
    const columnFormat = (type: string | undefined) => {
        switch (type) {
            case 'date':
                return (field: GridCellValue): GridCellValue =>
                    field ? new Date(field as string).toLocaleDateString('en-UK') : '-';
            default:
                return (field: GridCellValue): GridCellValue => `${field || '-'}`;
        }
    };

    const filterColumns = Object.keys(columnsProps);
    return Object.keys(data.jobs[0])
        .filter((key) => filterColumns.includes(key))
        .sort((a, b) => filterColumns.indexOf(a) - filterColumns.indexOf(b))
        .map((key) => {
            return {
                headerName: columnsProps[key]?.headerName || key,
                field: key,
                width: columnsProps[key]?.width || 150,
                valueFormatter: (params: GridValueFormatterParams): GridCellValue =>
                    columnFormat(columnsProps[key]?.type)(params.value),
                ...(columnsProps[key].renderCell && { renderCell: columnsProps[key].renderCell }),
            };
        });
};

interface Props {
    contractCustomerDetails: {
        name: string;
        phoneNumber: string;
        address: string;
    };
    claimStatus: string | null | undefined;
    isLoadingContract: boolean;
    isLoading: boolean;
    isError: boolean;
    data: DatabaseJobs | undefined;
}

export const Jobs = ({
    contractCustomerDetails,
    claimStatus,
    isLoadingContract,
    data,
    isLoading,
    isError,
}: Props) => {
    const [isCreateNewJobModalOpen, toggleIsCreateNewJobModalOpen] = useToggle(false);
    const urlParams = useParams();

    if (!urlParams.claimId) {
        return null;
    }

    if (isLoading || isLoadingContract) {
        return (
            <div className={styles.jobContainer}>
                <h5>Jobs</h5>
                <SkeletonLoader repeat={3} />
            </div>
        );
    }

    if (!data || isError || data.jobs.length === 0) {
        return (
            <div className={styles.jobContainer}>
                <Alert severity="error">
                    <AlertTitle>Job Details Error</AlertTitle>
                    Unable to load job information for id
                </Alert>
            </div>
        );
    }

    const rows = data.jobs.map((item) => {
        return { key: item.id, ...item };
    });
    const lastestJob = data.jobs[data.jobs.length - 1];

    return (
        <section className={styles.jobsContainer}>
            <div className={styles.titleWrapper}>
                <h5>Jobs</h5>
                {claimStatus === 'Investigation Required' && (
                    <SecondaryButton type="button" onClick={() => toggleIsCreateNewJobModalOpen()}>
                        Send to 3rd Party
                    </SecondaryButton>
                )}
            </div>
            <CreateNewJobModal
                open={isCreateNewJobModalOpen}
                closeModal={() => toggleIsCreateNewJobModalOpen()}
                job={lastestJob}
                contractCustomerDetails={contractCustomerDetails}
            />
            <Table
                rows={rows}
                columns={generateColumns(data)}
                hideFooter
                defaultSort={{
                    column: 'sk_created_date',
                    direction: 'desc',
                }}
            />
        </section>
    );
};
