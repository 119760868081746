import { Button, TextInput } from '@HometreeEngineering/component-library';
import { useSalesPortalContext } from 'features/salesPortal/contexts/SalesPortalContext';
import { SalesPortalState } from 'features/salesPortal/types';
import {
    ALPHA_NUM_EXTENDED,
    EMAIL_REGEX,
    PHONE_NUMBER_REGEX,
} from 'features/salesPortal/utils/validationRegex';
import { useEffect, useState } from 'react';
import Checkbox from '../Checkbox';
import styles from './index.module.scss';

interface ContactInformationFormProps {
    onSubmit: () => void;
}

const inputRegex: Record<keyof SalesPortalState['contact'], RegExp | null> = {
    firstName: new RegExp(ALPHA_NUM_EXTENDED),
    lastName: new RegExp(ALPHA_NUM_EXTENDED),
    phone: new RegExp(PHONE_NUMBER_REGEX),
    phonePrefix: null,
    email: new RegExp(EMAIL_REGEX),
    marketing_email: null,
    marketing_phone: null,
    marketing_sms: null,
};

const isInputValid = (field: keyof typeof inputRegex, value?: string | boolean) => {
    if (inputRegex[field] === null) return true;
    if (!value) return false;
    if (!inputRegex[field]!.test(value as string)) return false;
    return true;
};

export const ContactInformationForm = ({ onSubmit }: ContactInformationFormProps) => {
    const {
        state: { contact: contactState, formSectionValid },
        dispatch,
    } = useSalesPortalContext();

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    });

    const handleInputBlur = (field: keyof typeof inputRegex) => () => {
        const errorMessageToField = {
            firstName: 'This field must contain letters and digits only',
            lastName: 'This field must contain letters and digits only',
            phone: 'This field must contain 10 or 11 digits and no country code',
            email: 'This field must follow a valid email format',
        };

        if (typeof errorMessageToField[field as keyof typeof errorMessageToField] === 'undefined') {
            return;
        }

        if ((contactState[field] || '') === '') {
            setFormErrors((prevState) => ({ ...prevState, [field]: 'This field is required' }));
        }

        if (!isInputValid(field, contactState[field])) {
            setFormErrors((prevState) => ({
                ...prevState,
                [field]: errorMessageToField[field as keyof typeof errorMessageToField],
            }));
        } else {
            setFormErrors((prevState) => ({ ...prevState, [field]: '' }));
        }
    };

    useEffect(() => {
        let isValid = false;
        if (
            isInputValid('firstName', contactState.firstName) &&
            isInputValid('lastName', contactState.lastName) &&
            isInputValid('email', contactState.email) &&
            isInputValid('phone', contactState.phone)
        ) {
            isValid = true;
        }

        if (formSectionValid.contact !== isValid) {
            dispatch({
                type: 'setFormSectionValid',
                data: {
                    contact: isValid,
                },
            });
        }
    }, [
        contactState.firstName,
        contactState.lastName,
        contactState.email,
        contactState.phone,
        dispatch,
        formSectionValid,
    ]);

    const handleOnChange =
        (key: keyof SalesPortalState['contact']) => (value: string | boolean) => {
            if (typeof inputRegex[key] !== 'undefined' && isInputValid(key, value)) {
                setFormErrors((prevState) => ({ ...prevState, [key]: '' }));
            }

            dispatch({
                type: 'setContact',
                data: {
                    [key]: value,
                },
            });
        };

    const onNextClick = () => {
        if (formSectionValid.contact) {
            onSubmit();
        }
    };

    return (
        <div className={styles.contactInformationForm}>
            <form>
                <TextInput
                    label="First name"
                    id="First name"
                    value={contactState.firstName}
                    placeholder="First name"
                    className={styles.textInput}
                    onChange={handleOnChange('firstName')}
                    onBlur={handleInputBlur('firstName')}
                    error={formErrors.firstName}
                />
                <TextInput
                    label="Last name"
                    id="Last name"
                    value={contactState.lastName}
                    placeholder="Last name"
                    className={styles.textInput}
                    onChange={handleOnChange('lastName')}
                    onBlur={handleInputBlur('lastName')}
                    error={formErrors.lastName}
                />
                <TextInput
                    label="Phone number"
                    id="Phone number"
                    value={contactState.phone}
                    placeholder="Phone number"
                    className={styles.textInput}
                    onChange={handleOnChange('phone')}
                    onBlur={handleInputBlur('phone')}
                    error={formErrors.phone}
                />
                <TextInput
                    label="Email"
                    id="Email"
                    value={contactState.email}
                    placeholder="Email"
                    className={styles.textInput}
                    onChange={handleOnChange('email')}
                    onBlur={handleInputBlur('email')}
                    error={formErrors.email}
                />

                <div className={styles.marketingOptions}>
                    <h6>Keeping in touch</h6>
                    <p>
                        Occasionally, we&apos;d like to send you the latest offers and products from
                        Hometree Group, as well as the Hometree referral programme. You can opt-out
                        at any time via our customer portal. Let us know how you would like to keep
                        in touch.
                    </p>
                    <div className={styles.checkboxGroup}>
                        <Checkbox
                            id="Marketing_Email"
                            onChange={() =>
                                handleOnChange('marketing_email')(!contactState.marketing_email)
                            }
                            checked={contactState.marketing_email}
                        >
                            Email
                        </Checkbox>
                        <Checkbox
                            id="Marketing_Phone"
                            onChange={() =>
                                handleOnChange('marketing_phone')(!contactState.marketing_phone)
                            }
                            checked={contactState.marketing_phone}
                        >
                            Phone
                        </Checkbox>
                        <Checkbox
                            id="Marketing_SMS"
                            onChange={() =>
                                handleOnChange('marketing_sms')(!contactState.marketing_sms)
                            }
                            checked={contactState.marketing_sms}
                        >
                            Text
                        </Checkbox>
                    </div>
                </div>

                <Button
                    onClick={onNextClick}
                    isDisabled={!formSectionValid.contact}
                    customStyle={styles.nextBtn}
                >
                    Next
                </Button>
            </form>
        </div>
    );
};
