import styles from './index.module.scss';

interface Props {
    text: string | JSX.Element;
    buttons?: React.ReactNode;
}
export const Header = ({ text, buttons }: Props) => {
    return (
        <header className={styles.headerContainer}>
            <h5 className={styles.headerText}>{text}</h5>
            {buttons}
        </header>
    );
};
