import { Table } from '@HometreeEngineering/component-library';
import { Account } from 'api/contract/types';
import { AccountBlueIcon } from 'assets/pandoraIcons';
import { buildAccountTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo } from 'react';
import { getPandoraDomain } from 'utils/getPandoraDomain';
import styles from '../index.module.scss';

const AccountTable = ({ account }: { account: Account }) => {
    const accountHeaders = useMemo(
        () => [
            { key: 'active', value: 'Active' },
            { key: 'account_number', value: 'Account Number' },
            { key: 'first_name', value: 'First Name' },
            { key: 'last_name', value: 'Last Name' },
            { key: 'referral_code', value: 'Referral Code' },
            { key: 'email', value: 'Email' },
            { key: 'phone', value: 'Phone' },
            { key: 'correspondance_property', value: 'Correspondance Property' },
        ],
        []
    );
    const data = useMemo(() => buildAccountTableData(account), [account]);
    const pandoraDomain = getPandoraDomain(process.env.REACT_APP_STAGE);

    return (
        <div className={styles.container}>
            <div className={styles.sectionTitleContainer}>
                <h6 className={styles.sectionTitle}>LINKED ACCOUNT {account.id}</h6>
                <a
                    aria-label="navigate to account page"
                    className={styles.linkIcon}
                    href={`${pandoraDomain}/account/${account.id}`}
                >
                    <AccountBlueIcon />
                </a>
            </div>
            <Table headers={accountHeaders} data={data} customStyle={styles.differentHeader} />
        </div>
    );
};

export default AccountTable;
