import { addMonths, format } from 'date-fns';

export const generateBoilerMakeOptions = () => [
    {
        value: '',
        displayValue: 'Please select...',
        disabled: true,
    },
    { value: 'Worcester Bosch', displayValue: 'Worcester Bosch' },
    { value: 'Vaillant', displayValue: 'Vaillant' },
    { value: 'Ideal', displayValue: 'Ideal' },
    { value: 'Baxi', displayValue: 'Baxi' },
    { value: 'Potterton', displayValue: 'Potterton' },
    { value: 'Alpha', displayValue: 'Alpha' },
    { value: 'Vokera', displayValue: 'Vokera' },
    { value: 'Glow-worm', displayValue: 'Glow-worm' },
    { value: 'Ferroli', displayValue: 'Ferroli' },
    { value: 'Viessmann', displayValue: 'Viessmann' },
    { value: 'Intergas', displayValue: 'Intergas' },
    { value: 'Ravenheat', displayValue: 'Ravenheat' },
    { value: 'Biasi', displayValue: 'Biasi' },
    { value: 'Atag', displayValue: 'Atag' },
    { value: 'Keston', displayValue: 'Keston' },
    { value: 'Main', displayValue: 'Main' },
    { value: 'Ariston', displayValue: 'Ariston' },
    { value: 'Remeha', displayValue: 'Remeha' },
    {
        value: 'other',
        displayValue: 'Other',
    },
    {
        value: 'unknown',
        displayValue: "I don't know",
    },
];

export const generateBoilerAgeOptions = () => [
    {
        value: '',
        displayValue: 'Please select...',
        disabled: true,
    },
    { value: '1 - 3', displayValue: '1 - 3 years old' },
    { value: '3 - 5', displayValue: '3 - 5 years old' },
    { value: '5 - 10', displayValue: '5 - 10 years old' },
    { value: '10+', displayValue: '10+ years old' },
    {
        value: 'unknown',
        displayValue: "I don't know",
    },
];

export const generateNextServiceDateOptions = (startDate: Date) => {
    const options = [
        {
            value: '',
            displayValue: 'Please select...',
            disabled: true,
        },
    ];

    for (let i = 0; i < 12; i += 1) {
        const date = addMonths(startDate, i + 1);

        const monthIndex = date.getMonth();

        options.push({
            value: format(date, 'yyyy-MM'),
            displayValue: format(date, 'MMMM yyyy'),
            disabled: !!(monthIndex >= 9 || monthIndex <= 1),
        });
    }

    return options;
};
