export type Type = 'insurance' | 'maintenance';
export type CustomerType = 'homeowner' | 'landlord';
export type Billing = 'monthly' | 'annual';
export type Contribution = 0 | 60 | 95;

export enum PackageKey {
    BoilerEssentials = 'boilerEssentials',
    Boiler = 'boiler',
    HeatingEssentials = 'heatingEssentials',
    Heating = 'heating',
    Home = 'home',
    HomePlus = 'homePlus',
}

export interface SelectConfig {
    name: string;
    pricebook_id: number;
    source?: string;
    packages: {
        id: number;
        key: PackageKey;
        customer_type: CustomerType;
    }[];
}

export enum PromoCode {
    PERCENTAGE = 1,
    VALUE = 2,
    VOUCHER = 3,
    TRACKING = 4,
}

export interface SalesPortalState {
    plan: {
        pricebook_id: number;
        package_name: string;
        monthly_payment_amount: number;
        package_id: number;
        type: Type;
        customer_type: CustomerType;
        contribution: Contribution;
        billing: Billing;
        products: Product[];
        source: string;
        referral_code?: string;
        promocode?: {
            code: string;
            type: PromoCode;
            value: number;
        };
    };
    contact: {
        firstName: string;
        lastName: string;
        phonePrefix: '44';
        phone: string;
        email: string;
        marketing_email: boolean;
        marketing_phone: boolean;
        marketing_sms: boolean;
    };
    property: {
        searched: string;
        street: string;
        town: string;
        county: string;
        postcode: string;
        country: 'United Kingdom';
    };
    formSectionValid: {
        contact: boolean;
        property: boolean;
        payment: boolean;
    };
    payment: {
        method: 'cardPayment' | 'directDebit';
        startDate: Date;
        goCardless?: {
            billing_request_id: string;
            billing_request_flow_id: string;
            creditor_id: string;
            customer_id: string;
            customer_bank_account_id: string;
            customer_billing_detail_id: string;
            mandate_request_id: string;
            mandate_request_mandate_id: string;
        };
    };
    request: {
        isRequesting: boolean;
        isError?: boolean;
        errorCode?: string;
    };
}

export interface SalesPortalReducerAction {
    type:
        | 'setPlan'
        | 'setContact'
        | 'setProperty'
        | 'setPayment'
        | 'setRequest'
        | 'setFormSectionValid';
    data: Partial<
        | SalesPortalState['plan']
        | SalesPortalState['contact']
        | SalesPortalState['property']
        | SalesPortalState['payment']
        | SalesPortalState['request']
        | SalesPortalState['formSectionValid']
    >;
}

interface PricedEntity {
    name: string;
    product_id: number;
    featured: boolean;
    description: string;
    landlord_only: boolean;
    limit_per_contract: number;
}
export interface Product extends PricedEntity {
    included: boolean;
    position: number | null;
    type: 'product';
}
export interface Price {
    contribution: number;
    price: number;
}

export interface Package {
    name: string;
    type: 'Homeowner' | 'Landlord';
    is_insurance: boolean;
    products: Product[];
    package_id: number;
    prices: Price[];
}
export interface Addon extends PricedEntity {
    prices: Price[];
    type: 'addon';
}
export interface CatalogueResponseItem {
    pricebook_id: number;
    name: string;
    pricebook_entries: { packages: Package[]; addons: Addon[] };
}
export interface CatalogueResponseItems {
    items: CatalogueResponseItem[];
}

export interface CatalogueResponse {
    data?: CatalogueResponseItems;
    error?: Error;
}

export interface Errors {
    domain: string;
    reason: string;
    message: string;
}

export interface Error {
    code: number;
    message: string;
    errors?: Errors[];
}
